import * as docx from 'docx';

export const DOCX_STYLES: docx.IStylesOptions = {
  paragraphStyles: [
    {
      id: 'Normal',
      name: 'Normal',
      quickFormat: true,
      run: {
        size: 18,
        bold: false,
        italics: false,
        font: 'Arial',
      },
      paragraph: {
        spacing: {
          after: 36,
        },
      },
    },
    {
      id: 'Heading1',
      name: 'Heading 1',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: 32,
        bold: true,
        italics: false,
      },
      paragraph: {
        spacing: {
          after: 120,
        },
      },
    },
    {
      id: 'Heading2',
      name: 'Heading 2',
      basedOn: 'Normal',
      next: 'Normal',
      quickFormat: true,
      run: {
        size: 24,
        bold: true,
      },
      paragraph: {
        spacing: {
          before: 120,
          after: 120,
        },
      },
    },
  ],
};
