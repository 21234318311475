import { AbbreviationReplacer } from '../abbreviation_replacer';
import { BetweenPunctuation } from '../between_punctuation';
import { replacePunctuation } from '../punctuation_replacer';
import { Rule, Text } from '../utils';
import { LanguageBase } from './common/Base';
import { NUMBER_RULES } from './common/Common';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

const NUMBER_PERIOD_SPACE_RULE = new Rule(/(?<=\s\d)\.(?=\s)|(?<=\s\d\d)\.(?=\s)/, '∯');
const NEGATIVE_NUMBER_PERIOD_SPACE_RULE = new Rule(/(?<=-\d)\.(?=\s)|(?<=-\d\d)\.(?=\s)/, '∯');

class GermanAbbreviationReplacer extends AbbreviationReplacer {
  static SENTENCE_STARTERS: string[] = [
    'Am',
    'Auch',
    'Auf',
    'Bei',
    'Da',
    'Das',
    'Der',
    'Die',
    'Ein',
    'Eine',
    'Es',
    'Für',
    'Heute',
    'Ich',
    'Im',
    'In',
    'Ist',
    'Jetzt',
    'Mein',
    'Mit',
    'Nach',
    'So',
    'Und',
    'Warum',
    'Was',
    'Wenn',
    'Wer',
    'Wie',
    'Wir',
  ];

  replace(): string {
    const SingleLowerCaseLetterRule: Rule = new Rule(/(?<=\s[a-z])\.(?=\s)/, '∯');

    const SingleLowerCaseLetterAtStartOfLineRule: Rule = new Rule(/(?<=^[a-z])\.(?=\s)/, '∯');

    this.text = new Text(this.text).apply(
      this.lang.PossessiveAbbreviationRule,
      ...this.lang.SingleLetterAbbreviationRules.All,
      SingleLowerCaseLetterRule,
      SingleLowerCaseLetterAtStartOfLineRule,
    );

    this.text = this.searchForAbbreviationsInString(this.text);
    this.replaceMultiPeriodAbbreviations();
    this.text = new Text(this.text).apply(...this.lang.AmPmRules.All);
    this.text = this.replaceAbbreviationAsSentenceBoundary();
    return this.text;
  }

  scanForReplacements(txt: string, am: string, index: number, characterArray: string[]): string {
    return txt.replace(new RegExp(`(?<=${am})\\.(?=\\s)`, 'g'), '∯');
  }
}

class GermanBetweenPunctuation extends BetweenPunctuation {
  subPunctuationBetweenDoubleQuotes(txt: string): string {
    const BETWEEN_UNCONVENTIONAL_DOUBLE_QUOTE_DE_REGEX = /,,(?=([^"\\]+|\\{2}|\\.)*)\1"/;
    const BETWEEN_DOUBLE_QUOTES_DE_REGEX = /„(?=([^"\\]+|\\{2}|\\.)*)\1"/;

    if (txt.includes('„')) {
      return txt.replace(BETWEEN_DOUBLE_QUOTES_DE_REGEX, replacePunctuation);
    } else if (txt.includes(',,')) {
      return txt.replace(BETWEEN_UNCONVENTIONAL_DOUBLE_QUOTE_DE_REGEX, replacePunctuation);
    }
    return txt;
  }
}

export class German extends LanguageBase {
  readonly isoCode = 'de';

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'Ä',
      'ä',
      'adj',
      'adm',
      'adv',
      'art',
      'asst',
      'b.a',
      'b.s',
      'bart',
      'bldg',
      'brig',
      'bros',
      'bse',
      'buchst',
      'bzgl',
      'bzw',
      'c.-à-d',
      'ca',
      'capt',
      'chr',
      'cmdr',
      'co',
      'col',
      'comdr',
      'con',
      'corp',
      'cpl',
      'd.h',
      'd.j',
      'dergl',
      'dgl',
      'dkr',
      'dr ',
      'ens',
      'etc',
      'ev ',
      'evtl',
      'ff',
      'g.g.a',
      'g.u',
      'gen',
      'ggf',
      'gov',
      'hon',
      'hosp',
      'i.f',
      'i.h.v',
      'ii',
      'iii',
      'insp',
      'iv',
      'ix',
      'jun',
      'k.o',
      'kath ',
      'lfd',
      'lt',
      'ltd',
      'm.e',
      'maj',
      'med',
      'messrs',
      'mio',
      'mlle',
      'mm',
      'mme',
      'mr',
      'mrd',
      'mrs',
      'ms',
      'msgr',
      'mwst',
      'no',
      'nos',
      'nr',
      'o.ä',
      'op',
      'ord',
      'pfc',
      'ph',
      'pp',
      'prof',
      'pvt',
      'rep',
      'reps',
      'res',
      'rev',
      'rt',
      's.p.a',
      'sa',
      'sen',
      'sens',
      'sfc',
      'sgt',
      'sog',
      'sogen',
      'spp',
      'sr',
      'st',
      'std',
      'str  ',
      'supt',
      'surg',
      'u.a  ',
      'u.e',
      'u.s.w',
      'u.u',
      'u.ä',
      'usf',
      'usw',
      'v',
      'vgl',
      'vi',
      'vii',
      'viii',
      'vs',
      'x',
      'xi',
      'xii',
      'xiii',
      'xiv',
      'xix',
      'xv',
      'xvi',
      'xvii',
      'xviii',
      'xx',
      'z.b',
      'z.t',
      'z.z',
      'z.zt',
      'zt',
      'zzt',
      'univ.-prof',
      'o.univ.-prof',
      'ao.univ.prof',
      'ass.prof',
      'hon.prof',
      'univ.-doz',
      'univ.ass',
      'stud.ass',
      'projektass',
      'ass',
      'di',
      'dipl.-ing',
      'mag',
    ],
    PREPOSITIVE_ABBREVIATIONS: [],
    NUMBER_ABBREVIATIONS: ['art', 'ca', 'no', 'nos', 'nr', 'pp'],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };

  readonly Numbers = {
    ...NUMBER_RULES,
    NumberPeriodSpaceRule: NUMBER_PERIOD_SPACE_RULE,
    NegativeNumberPeriodSpaceRule: NEGATIVE_NUMBER_PERIOD_SPACE_RULE,
    All: [...NUMBER_RULES.All],
  };

  readonly MONTHS = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ];

  readonly AbbreviationReplacer = GermanAbbreviationReplacer;
  readonly BetweenPunctuation = GermanBetweenPunctuation;
}
