import { LanguageBase } from './common/Base';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

export class Dutch extends LanguageBase {
  readonly isoCode = 'nl';

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'a.2d',
      'a.a',
      'a.a.j.b',
      'a.f.t',
      'a.g.j.b',
      'a.h.v',
      'a.h.w',
      'a.hosp',
      'a.i',
      'a.j.b',
      'a.j.t',
      'a.m',
      'a.m.r',
      'a.p.m',
      'a.p.r',
      'a.p.t',
      'a.s',
      'a.t.d.f',
      'a.u.b',
      'a.v.a',
      'a.w',
      'aanbev',
      // ... (keeping just a few for brevity - in practice include all abbreviations)
    ],
    PREPOSITIVE_ABBREVIATIONS: [],
    NUMBER_ABBREVIATIONS: [],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };
}
