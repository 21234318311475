import React, { useCallback, useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { Plus as PlusIcon } from '@phosphor-icons/react';
import { captureException } from '@sentry/react';
import toast from 'react-hot-toast';

import { useTeam } from '@/app/team/context/TeamContext';
import { IPaginationVariables, usePagination } from '../../../hooks/usePagination';
import { PageHeader } from '../../../components/PageHeader';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { formatDate } from '../../../utils/date';
import { SpinnerBlock } from '../../../components/Spinner';
import { Pagination } from '../../../components/Pagination';
import { fetchEndpointData } from '../../../utils/fetch.client';
import type { ResponseType as CategoryTemplatesResponseType } from '../endpoints/ListCategoryTemplatesEndpoint';
import type { BodyType as CreateCategoryTemplateBody } from '../endpoints/CreateCategoryTemplateEndpoint';
import { CategoryTemplateFormDialog } from './CategoryTemplateDialog';
import { getDisplayError } from '@/utils/get-display-error';

export const CategoryTemplatesPage = () => {
  const { team } = useTeam();
  const [refreshToken, setRefreshToken] = useState(Date.now());
  const [searchParams, setSearchParams] = useSearchParams();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const pageFetcher = useCallback(
    async (variables: IPaginationVariables) => {
      const query = new URLSearchParams();
      query.set('teamId', team.id);
      if (variables.cursor) {
        query.set('id', variables.cursor);
      }
      query.set('take', variables.take.toString());
      const result = await fetchEndpointData<CategoryTemplatesResponseType>(
        `/api/v1/category-template/list?${query.toString()}`,
      );
      return result.items;
    },
    [team.id],
  );
  const page = usePagination({
    refreshToken,
    key: 'id',
    pageSize: 40,
    cursor: searchParams.get('pageCursor'),
    fetcher: pageFetcher,
  });

  const pageCursor = page.variables.cursor;
  useEffect(() => {
    if (!pageCursor) return;

    setSearchParams((prev) => {
      prev.set('pageCursor', pageCursor);
      return prev;
    });
  }, [pageCursor]);

  const history = page.data ?? [];
  const title = 'Category Templates';
  return (
    <div className="page-content">
      <PageHeader title={title} />

      <div className="flex justify-between mb-4">
        <Breadcrumb
          items={[
            {
              name: title,
            },
          ]}
        />
      </div>

      <CategoryTemplateFormDialog
        isOpen={showCreateDialog}
        setIsOpen={setShowCreateDialog}
        title="Create category template"
        submitText="Create"
        onSubmit={async (values, helpers) => {
          try {
            const payload: CreateCategoryTemplateBody = {
              teamId: team.id,
              data: {
                name: values.name,
              },
            };
            await fetchEndpointData('/api/v1/category-template/create', {
              method: 'POST',
              body: payload,
            });
            setRefreshToken(Date.now());
            helpers.resetForm();
            toast.success('Category created');
          } catch (err) {
            captureException(err);
            toast.error(`Failed to create category: ${getDisplayError(err)}`);
            throw err;
          }
        }}
      />

      {!history.length && page.isFetching ? (
        <div>
          <SpinnerBlock message="Loading..." className="h-screen" />
        </div>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          <div
            className="card flex justify-center items-center gap-2 text-lg font-medium"
            data-clickable="true"
            onClick={() => {
              setShowCreateDialog(true);
            }}
          >
            <div className="flex-shrink-0">
              <PlusIcon />
            </div>
            Create Category Template
          </div>

          {history.map((v) => {
            return (
              <Link to={v.id} key={v.id} className="card" data-clickable="true">
                <div className="text-lg font-medium">{v.name}</div>
                <div>{formatDate(v.createdAt)}</div>
              </Link>
            );
          })}
        </div>
      )}

      <div className="py-4">
        <Pagination
          hasPrevious={page.hasPrevious}
          previous={page.previous}
          hasNext={page.hasNext}
          next={page.next}
          isFetching={page.isFetching}
        />
      </div>
    </div>
  );
};
