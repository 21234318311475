import { Rule } from '../../utils';

// Basic punctuation and constants
export const PUNCTUATIONS = ['。', '．', '.', '！', '!', '?', '？'];

// Individual rules
export const GEO_LOCATION_RULE = new Rule(/(?<=[a-zA-z]°)\.(?=\s*\d+)/, '∯');
export const FILE_FORMAT_RULE = new Rule(
  /(?<=\s)\.(?=(jpe?g|png|gif|tiff?|pdf|ps|docx?|xlsx?|svg|bmp|tga|exif|odt|html?|txt|rtf|bat|sxw|xml|zip|exe|msi|blend|wmv|mp[34]|pptx?|flac|rb|cpp|cs|js)\s)/,
  '∯',
);
export const SINGLE_NEW_LINE_RULE = new Rule(/\n/, 'ȹ');
export const QUESTION_MARK_IN_QUOTATION_RULE = new Rule(/\?(?=('|"))/, '&ᓷ&');
export const EXTRA_WHITE_SPACE_RULE = new Rule(/\s{3,}/, ' ');
export const SUB_SINGLE_QUOTE_RULE = new Rule(/&⎋&/, "'");

// Abbreviation lists
export const ABBREVIATIONS = [
  'adj',
  'adm',
  'adv',
  'al',
  'ala',
  'alta',
  'apr',
  'arc',
  'ariz',
  'ark',
  'art',
  // ... rest of the abbreviations (kept same as original)
  'fig',
];

export const PREPOSITIVE_ABBREVIATIONS = [
  'adm',
  'attys',
  'brig',
  'capt',
  'cmdr',
  'col',
  'cpl',
  'det',
  'dr',
  'gen',
  // ... rest of prepositive abbreviations (kept same as original)
  'fig',
];

export const NUMBER_ABBREVIATIONS = ['art', 'ext', 'no', 'nos', 'p', 'pp'];

// Abbreviation rules
export const WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE = new Rule(/([a-zA-Z0-9_])(\.)([a-zA-Z0-9_])/, '$1∮$3');

// Double punctuation rules
export const DOUBLE_PUNCTUATION_FIRST_RULE = new Rule(/\?!/, '☉');
export const DOUBLE_PUNCTUATION_SECOND_RULE = new Rule(/!\?/, '☈');
export const DOUBLE_PUNCTUATION_THIRD_RULE = new Rule(/\?\?/, '☇');
export const DOUBLE_PUNCTUATION_FOURTH_RULE = new Rule(/!!/, '☄');
export const DOUBLE_PUNCTUATION_REGEX = /\?!|!\?|\?\?|!!/;

export const DOUBLE_PUNCTUATION_RULES = {
  FirstRule: DOUBLE_PUNCTUATION_FIRST_RULE,
  SecondRule: DOUBLE_PUNCTUATION_SECOND_RULE,
  ThirdRule: DOUBLE_PUNCTUATION_THIRD_RULE,
  FourthRule: DOUBLE_PUNCTUATION_FOURTH_RULE,
  DoublePunctuation: DOUBLE_PUNCTUATION_REGEX,
  All: [
    DOUBLE_PUNCTUATION_FIRST_RULE,
    DOUBLE_PUNCTUATION_SECOND_RULE,
    DOUBLE_PUNCTUATION_THIRD_RULE,
    DOUBLE_PUNCTUATION_FOURTH_RULE,
  ],
};

// Exclamation point rules
export const EXCLAMATION_IN_QUOTATION_RULE = new Rule(/!(?=('|"))/, '&ᓴ&');
export const EXCLAMATION_BEFORE_COMMA_MID_SENTENCE_RULE = new Rule(/!(?=,\s[a-z])/, '&ᓴ&');
export const EXCLAMATION_MID_SENTENCE_RULE = new Rule(/!(?=\s[a-z])/, '&ᓴ&');

export const EXCLAMATION_POINT_RULES = {
  InQuotationRule: EXCLAMATION_IN_QUOTATION_RULE,
  BeforeCommaMidSentenceRule: EXCLAMATION_BEFORE_COMMA_MID_SENTENCE_RULE,
  MidSentenceRule: EXCLAMATION_MID_SENTENCE_RULE,
  All: [EXCLAMATION_IN_QUOTATION_RULE, EXCLAMATION_BEFORE_COMMA_MID_SENTENCE_RULE, EXCLAMATION_MID_SENTENCE_RULE],
};

// Sub symbols rules
const SUB_SYMBOL_RULES_MAP = {
  Period: new Rule(/∯/, '.'),
  ArabicComma: new Rule(/♬/, '،'),
  SemiColon: new Rule(/♭/, ':'),
  FullWidthPeriod: new Rule(/&ᓰ&/, '。'),
  SpecialPeriod: new Rule(/&ᓱ&/, '．'),
  FullWidthExclamation: new Rule(/&ᓳ&/, '！'),
  ExclamationPoint: new Rule(/&ᓴ&/, '!'),
  QuestionMark: new Rule(/&ᓷ&/, '?'),
  FullWidthQuestionMark: new Rule(/&ᓸ&/, '？'),
  MixedDoubleQE: new Rule(/☉/, '?!'),
  MixedDoubleQQ: new Rule(/☇/, '??'),
  MixedDoubleEQ: new Rule(/☈/, '!?'),
  MixedDoubleEE: new Rule(/☄/, '!!'),
  LeftParens: new Rule(/&✂&/, '('),
  RightParens: new Rule(/&⌬&/, ')'),
  TemporaryEndingPunctutation: new Rule(/ȸ/, ''),
  Newline: new Rule(/ȹ/, '\n'),
};

export const SUB_SYMBOLS_RULES = {
  ...SUB_SYMBOL_RULES_MAP,
  All: Object.values(SUB_SYMBOL_RULES_MAP),
};

// Ellipsis rules
const ELLIPSIS_RULES_MAP = {
  ThreeConsecutiveRule: new Rule(/\.\.\.(?=\s+[A-Z])/, '☏☏.'),
  FourConsecutiveRule: new Rule(/(?<=\S)\.{3}(?=\.\s[A-Z])/, 'ƪƪƪ'),
  ThreeSpaceRule: new Rule(/(\s\.){3}\s/, '♟♟♟♟♟♟♟'),
  FourSpaceRule: new Rule(/(?<=[a-z])(\.\s){3}\.($|\n)/, '♝♝♝♝♝♝♝'),
  OtherThreePeriodRule: new Rule(/\.\.\./, 'ƪƪƪ'),
};

export const ELLIPSIS_RULES = {
  ...ELLIPSIS_RULES_MAP,
  All: [
    ELLIPSIS_RULES_MAP.ThreeSpaceRule,
    ELLIPSIS_RULES_MAP.FourSpaceRule,
    ELLIPSIS_RULES_MAP.FourConsecutiveRule,
    ELLIPSIS_RULES_MAP.ThreeConsecutiveRule,
    ELLIPSIS_RULES_MAP.OtherThreePeriodRule,
  ],
};

// Reinsert ellipsis rules
const REINSERT_ELLIPSIS_RULES_MAP = {
  SubThreeConsecutivePeriod: new Rule(/ƪƪƪ/, '...'),
  SubThreeSpacePeriod: new Rule(/♟♟♟♟♟♟♟/, ' . . . '),
  SubFourSpacePeriod: new Rule(/♝♝♝♝♝♝♝/, '. . . .'),
  SubTwoConsecutivePeriod: new Rule(/☏☏/, '..'),
  SubOnePeriod: new Rule(/∮/, '.'),
};

export const REINSERT_ELLIPSIS_RULES = {
  ...REINSERT_ELLIPSIS_RULES_MAP,
  All: Object.values(REINSERT_ELLIPSIS_RULES_MAP),
};

// Sentence starters
export const SENTENCE_STARTERS = [
  'A',
  'Being',
  'Did',
  'For',
  'He',
  'How',
  'However',
  'I',
  'In',
  'It',
  'Millions',
  'More',
  'She',
  'That',
  'The',
  'There',
  'They',
  'We',
  'What',
  'When',
  'Where',
  'Who',
  'Why',
];
