import { useField } from 'formik';
import React, { useMemo } from 'react';

import { ISimpleSelectItem, SimpleSelect } from '../../../components/select/SimpleSelect';
import { InputWrapper } from '../../../components/InputWrapper';
import { useCategoryTemplates } from '../hooks/useCategoryTemplates';

export interface ICategoryTemplateSelectFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const CategoryTemplateSelectField: React.FC<ICategoryTemplateSelectFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled } = props;
  const { data } = useCategoryTemplates();
  const [field, meta, helpers] = useField({ name });

  const templates = data?.items ?? [];
  const items = useMemo(() => {
    return templates.map((v) => {
      return {
        key: v.id,
        name: v.name,
      };
    });
  }, [templates]);

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <SimpleSelect
        items={items}
        selectedItem={field.value ? (items.find((v) => v.key === field.value) ?? null) : null}
        onSelect={(value: ISimpleSelectItem | null) => {
          helpers.setValue(value?.key ?? null);
        }}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
        isOptional={true}
      />
    </InputWrapper>
  );
};
