import { AbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';

class UrduAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

export class Urdu extends LanguageBase {
  readonly isoCode = 'ur';

  readonly AbbreviationReplacer = UrduAbbreviationReplacer;

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[۔؟!?]|.*?$';

  readonly Punctuations = ['?', '!', '۔', '؟'];
}
