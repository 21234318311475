import { Text } from './utils';
import { ListItemReplacer } from './list_item_replacer';
import { ExclamationWords } from './exclamation_words';
import { BetweenPunctuation } from './between_punctuation';
import { AbbreviationReplacer } from './abbreviation_replacer';
import { LanguageBase } from './lang/common/Base';

export class Processor {
  text: string;
  lang: LanguageBase;
  charSpan: boolean;

  constructor(text: string, lang: any, charSpan: boolean = false) {
    this.text = text;
    this.lang = lang;
    this.charSpan = charSpan;
  }

  process(): string[] {
    if (!this.text) {
      return [];
    }
    this.text = this.text.replace(/\n/g, '\r');
    const li = new ListItemReplacer(this.text);
    this.text = li.addLineBreak();
    this.replaceAbbreviations();
    this.replaceNumbers();
    this.replacePeriodInDates();
    this.replaceContinuousPunctuation();
    this.replacePeriodsBeforeNumericReferences();
    this.text = new Text(this.text).apply(
      this.lang.Abbreviation.WithMultiplePeriodsAndEmailRule,
      this.lang.GeoLocationRule,
      this.lang.FileFormatRule,
    );
    return this.splitIntoSegments();
  }

  rmNoneFlatten(sents: (string | string[])[]): string[] {
    sents = sents.filter(Boolean);
    if (!sents.some((s) => Array.isArray(s))) {
      return sents as string[];
    }
    const newSents: string[] = [];
    for (const sent of sents) {
      if (Array.isArray(sent)) {
        newSents.push(...sent);
      } else {
        newSents.push(sent);
      }
    }
    return newSents;
  }

  splitIntoSegments(): string[] {
    this.checkForParensBetweenQuotes();
    let _sents = this.text.split('\r');
    _sents = this.rmNoneFlatten(_sents);
    _sents = _sents.map((s) => new Text(s).apply(this.lang.SingleNewLineRule, ...this.lang.EllipsisRules.All));
    const sents = _sents.map((s) => this.checkForPunctuation(s));
    const flattenedSents = this.rmNoneFlatten(sents);

    const postprocessedSents: string[] = [];
    for (const sent of flattenedSents) {
      const processedSent = new Text(sent).apply(...this.lang.SubSymbolsRules.All);
      const postProcessSent = this.postProcessSegments(processedSent);
      if (postProcessSent) {
        if (typeof postProcessSent === 'string') {
          postprocessedSents.push(postProcessSent);
        } else if (Array.isArray(postProcessSent)) {
          postprocessedSents.push(...postProcessSent);
        }
      }
    }

    return postprocessedSents.map((ns) => new Text(ns).apply(this.lang.SubSingleQuoteRule));
  }

  postProcessSegments(txt: string): string | string[] | null {
    if (txt.length > 2 && /\A[a-zA-Z]*\Z/g.test(txt)) {
      return txt;
    }

    if (txt.startsWith('\t')) {
      return null;
    }

    txt = new Text(txt).apply(...this.lang.ReinsertEllipsisRules.All);
    if (new RegExp(this.lang.QUOTATION_AT_END_OF_SENTENCE_REGEX).test(txt)) {
      return txt.split(new RegExp(this.lang.SPLIT_SPACE_QUOTATION_AT_END_OF_SENTENCE_REGEX));
    } else {
      txt = txt.replace(/\n/g, '');
      return txt.trim();
    }
  }

  checkForParensBetweenQuotes(): void {
    const parenReplace = (match: string): string => {
      let result = match.replace(/\s(?=\()/g, '\r');
      result = result.replace(/(?<=\))\s/g, '\r');
      return result;
    };
    this.text = this.text.replace(new RegExp(this.lang.PARENS_BETWEEN_DOUBLE_QUOTES_REGEX, 'g'), parenReplace);
  }

  replaceContinuousPunctuation(): void {
    const continuousPuncsReplace = (match: string): string => {
      let result = match.replace(/!/g, '&ᓴ&');
      result = result.replace(/\?/g, '&ᓷ&');
      return result;
    };
    this.text = this.text.replace(new RegExp(this.lang.CONTINUOUS_PUNCTUATION_REGEX, 'g'), continuousPuncsReplace);
  }

  replacePeriodInDates(): void {
    for (const month of this.lang.MONTHS) {
      const regex = new RegExp(`(?<=\\d)\\.(?=\\s*${month})`, 'g');
      this.text = this.text.replace(regex, '∯');
    }
  }

  replacePeriodsBeforeNumericReferences(): void {
    this.text = this.text.replace(new RegExp(this.lang.NUMBERED_REFERENCE_REGEX, 'g'), '∯$2\r$7');
  }

  checkForPunctuation(txt: string): string | string[] {
    if (this.lang.Punctuations.some((p) => txt.includes(p))) {
      return this.processText(txt);
    }
    return [txt];
  }

  processText(txt: string): string[] {
    if (txt.length > 0 && !this.lang.Punctuations.includes(txt[txt.length - 1]!)) {
      txt += 'ȸ';
    }
    txt = ExclamationWords.applyRules(txt);
    txt = this.betweenPunctuation(txt);

    if (!new RegExp(this.lang.DoublePunctuationRules.DoublePunctuation).test(txt)) {
      txt = new Text(txt).apply(...this.lang.DoublePunctuationRules.All);
    }
    txt = new Text(txt).apply(this.lang.QuestionMarkInQuotationRule, ...this.lang.ExclamationPointRules.All);
    txt = new ListItemReplacer(txt).replaceParens();
    return this.sentenceBoundaryPunctuation(txt);
  }

  replaceNumbers(): void {
    this.text = new Text(this.text).apply(...this.lang.Numbers.All);
  }

  abbreviationsReplacer(): AbbreviationReplacer {
    if (this.lang.AbbreviationReplacer) {
      return new this.lang.AbbreviationReplacer(this.text, this.lang);
    } else {
      return new AbbreviationReplacer(this.text, this.lang);
    }
  }

  replaceAbbreviations(): void {
    this.text = this.abbreviationsReplacer().replace();
  }

  betweenPunctuationProcessor(txt: string): BetweenPunctuation {
    if (this.lang.BetweenPunctuation) {
      return new this.lang.BetweenPunctuation(txt);
    } else {
      return new BetweenPunctuation(txt);
    }
  }

  betweenPunctuation(txt: string): string {
    return this.betweenPunctuationProcessor(txt).replace();
  }

  sentenceBoundaryPunctuation(txt: string): string[] {
    if (this.lang.ReplaceColonBetweenNumbersRule) {
      txt = new Text(txt).apply(this.lang.ReplaceColonBetweenNumbersRule);
    }

    if (this.lang.ReplaceNonSentenceBoundaryCommaRule) {
      txt = new Text(txt).apply(this.lang.ReplaceNonSentenceBoundaryCommaRule);
    }

    txt = txt.replace(/&ᓴ&$/g, '!');

    const matches = txt.match(new RegExp(this.lang.SENTENCE_BOUNDARY_REGEX, 'g')) || [];
    return matches;
  }
}
