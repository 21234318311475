import { Rule, Text } from './utils';

export class ListItemReplacer {
  static readonly ROMAN_NUMERALS =
    'i ii iii iv v vi vii viii ix x xi xii xiii xiv x xi xii xiii xv xvi xvii xviii xix xx'.split(' ');
  static readonly LATIN_NUMERALS = Array.from('abcdefghijklmnopqrstuvwxyz');

  static readonly ALPHABETICAL_LIST_WITH_PERIODS = /(?<=^)[a-z](?=\.)|(?<=\A)[a-z](?=\.)|(?<=\s)[a-z](?=\.)/g;
  static readonly ALPHABETICAL_LIST_WITH_PARENS =
    /(?<=\()[a-z]+(?=\))|(?<=^)[a-z]+(?=\))|(?<=\A)[a-z]+(?=\))|(?<=\s)[a-z]+(?=\))/gi;

  static readonly SubstituteListPeriodRule = new Rule('♨', '∯');
  static readonly ListMarkerRule = new Rule('☝', '');

  static readonly SpaceBetweenListItemsFirstRule = new Rule(/(?<=\S\S)\s(?=\S\s*\d+♨)/, '\r');
  static readonly SpaceBetweenListItemsSecondRule = new Rule(/(?<=\S\S)\s(?=\d{1,2}♨)/, '\r');
  static readonly SpaceBetweenListItemsThirdRule = new Rule(/(?<=\S\S)\s(?=\d{1,2}☝)/, '\r');

  static readonly NUMBERED_LIST_REGEX_1 =
    /\s\d{1,2}(?=\.\s)|^\d{1,2}(?=\.\s)|\s\d{1,2}(?=\.\))|^\d{1,2}(?=\.\))|(?<=\s\-)\d{1,2}(?=\.\s)|(?<=^\-)\d{1,2}(?=\.\s)|(?<=\s\⁃)\d{1,2}(?=\.\s)|(?<=^\⁃)\d{1,2}(?=\.\s)|(?<=s\-)\d{1,2}(?=\.\))|(?<=^\-)\d{1,2}(?=\.\))|(?<=\s\⁃)\d{1,2}(?=\.\))|(?<=^\⁃)\d{1,2}(?=\.\))/g;
  static readonly NUMBERED_LIST_REGEX_2 =
    /(?<=\s)\d{1,2}\.(?=\s)|^\d{1,2}\.(?=\s)|(?<=\s)\d{1,2}\.(?=\))|^\d{1,2}\.(?=\))|(?<=\s\-)\d{1,2}\.(?=\s)|(?<=^\-)\d{1,2}\.(?=\s)|(?<=\s\⁃)\d{1,2}\.(?=\s)|(?<=^\⁃)\d{1,2}\.(?=\s)|(?<=\s\-)\d{1,2}\.(?=\))|(?<=^\-)\d{1,2}\.(?=\))|(?<=\s\⁃)\d{1,2}\.(?=\))|(?<=^\⁃)\d{1,2}\.(?=\))/g;
  static readonly NUMBERED_LIST_PARENS_REGEX = /\d{1,2}(?=\)\s)/g;

  static readonly EXTRACT_ALPHABETICAL_LIST_LETTERS_REGEX =
    /\([a-z]+(?=\))|(?<=^)[a-z]+(?=\))|(?<=\A)[a-z]+(?=\))|(?<=\s)[a-z]+(?=\))/gi;
  static readonly ALPHABETICAL_LIST_LETTERS_AND_PERIODS_REGEX = /(?<=^)[a-z]\.|(?<=\A)[a-z]\.|(?<=\s)[a-z]\./gi;
  static readonly ROMAN_NUMERALS_IN_PARENTHESES =
    /\(((?=[mdclxvi])m*(c[md]|d?c*)(x[cl]|l?x*)(i[xv]|v?i*))\)(?=\s[A-Z])/g;

  text: string;

  constructor(text: string) {
    this.text = text;
  }

  addLineBreak(): string {
    this.formatAlphabeticalLists();
    this.formatRomanNumeralLists();
    this.formatNumberedListWithPeriods();
    this.formatNumberedListWithParens();
    return this.text;
  }

  replaceParens(): string {
    return this.text.replace(ListItemReplacer.ROMAN_NUMERALS_IN_PARENTHESES, '&✂&$1&⌬&');
  }

  formatNumberedListWithParens(): void {
    this.replaceParensInNumberedList();
    this.addLineBreaksForNumberedListWithParens();
    this.text = new Text(this.text).apply(ListItemReplacer.ListMarkerRule);
  }

  replacePeriodsInNumberedList(): void {
    this.scanLists(ListItemReplacer.NUMBERED_LIST_REGEX_1, ListItemReplacer.NUMBERED_LIST_REGEX_2, '♨', true);
  }

  formatNumberedListWithPeriods(): void {
    this.replacePeriodsInNumberedList();
    this.addLineBreaksForNumberedListWithPeriods();
    this.text = new Text(this.text).apply(ListItemReplacer.SubstituteListPeriodRule);
  }

  formatAlphabeticalLists(): void {
    this.text = this.addLineBreaksForAlphabeticalListWithPeriods(false);
    this.text = this.addLineBreaksForAlphabeticalListWithParens(false);
  }

  formatRomanNumeralLists(): void {
    this.text = this.addLineBreaksForAlphabeticalListWithPeriods(true);
    this.text = this.addLineBreaksForAlphabeticalListWithParens(true);
  }

  addLineBreaksForAlphabeticalListWithPeriods(romanNumeral: boolean): string {
    return this.iterateAlphabetArray(ListItemReplacer.ALPHABETICAL_LIST_WITH_PERIODS, false, romanNumeral);
  }

  addLineBreaksForAlphabeticalListWithParens(romanNumeral: boolean): string {
    return this.iterateAlphabetArray(ListItemReplacer.ALPHABETICAL_LIST_WITH_PARENS, true, romanNumeral);
  }

  scanLists(regex1: RegExp, regex2: RegExp, replacement: string, strip: boolean = false): void {
    const matches = this.text.match(regex1);
    if (!matches) return;

    const listArray = matches.map(Number);

    listArray.forEach((item, ind) => {
      if (ind < listArray.length - 1 && item + 1 === listArray[ind + 1]) {
        this.substituteFoundListItems(regex2, item, strip, replacement);
      } else if (ind > 0) {
        if (
          item - 1 === listArray[ind - 1] ||
          (item === 0 && listArray[ind - 1] === 9) ||
          (item === 9 && listArray[ind - 1] === 0)
        ) {
          this.substituteFoundListItems(regex2, item, strip, replacement);
        }
      }
    });
  }

  substituteFoundListItems(regex: RegExp, each: number, strip: boolean, replacement: string): void {
    this.text = this.text.replace(regex, (match) => {
      if (strip) {
        match = match.trim();
      }
      const chompedMatch = match.length === 1 ? match : match.replace(/[\.\])]/g, '');
      return String(each) === chompedMatch ? `${each}${replacement}` : match;
    });
  }

  addLineBreaksForNumberedListWithPeriods(): void {
    if (this.text.includes('♨') && !/♨.+(\n|\r).+♨/.test(this.text) && !/for\s\d{1,2}♨\s[a-z]/.test(this.text)) {
      this.text = new Text(this.text).apply(
        ListItemReplacer.SpaceBetweenListItemsFirstRule,
        ListItemReplacer.SpaceBetweenListItemsSecondRule,
      );
    }
  }

  replaceParensInNumberedList(): void {
    this.scanLists(ListItemReplacer.NUMBERED_LIST_PARENS_REGEX, ListItemReplacer.NUMBERED_LIST_PARENS_REGEX, '☝');
    this.scanLists(ListItemReplacer.NUMBERED_LIST_PARENS_REGEX, ListItemReplacer.NUMBERED_LIST_PARENS_REGEX, '☝');
  }

  addLineBreaksForNumberedListWithParens(): void {
    if (this.text.includes('☝') && !/☝.+\n.+☝|☝.+\r.+☝/.test(this.text)) {
      this.text = new Text(this.text).apply(ListItemReplacer.SpaceBetweenListItemsThirdRule);
    }
  }

  replaceAlphabetList(a: string): string {
    return this.text.replace(ListItemReplacer.ALPHABETICAL_LIST_LETTERS_AND_PERIODS_REGEX, (match) => {
      const matchWoPeriod = match.replace('.', '');
      return matchWoPeriod === a ? `\r${matchWoPeriod}∯` : match;
    });
  }

  replaceAlphabetListParens(a: string): string {
    return this.text.replace(ListItemReplacer.EXTRACT_ALPHABETICAL_LIST_LETTERS_REGEX, (match) => {
      if (match.includes('(')) {
        const matchWoParen = match.replace('(', '');
        return matchWoParen === a ? `\r&✂&${matchWoParen}` : match;
      }
      return match === a ? `\r${match}` : match;
    });
  }

  replaceCorrectAlphabetList(a: string, parens: boolean): string {
    return parens ? this.replaceAlphabetListParens(a) : this.replaceAlphabetList(a);
  }

  lastArrayItemReplacement(a: string, i: number, alphabet: string[], listArray: string[], parens: boolean): string {
    if (
      (alphabet.length === 0 && listArray.length === 0) ||
      !alphabet.includes(listArray[i - 1]!) ||
      !alphabet.includes(a)
    ) {
      return this.text;
    }
    if (Math.abs(alphabet.indexOf(listArray[i - 1]!) - alphabet.indexOf(a)) !== 1) {
      return this.text;
    }
    return this.replaceCorrectAlphabetList(a, parens);
  }

  otherItemsReplacement(a: string, i: number, alphabet: string[], listArray: string[], parens: boolean): string {
    if (
      (alphabet.length === 0 && listArray.length === 0) ||
      !alphabet.includes(listArray[i - 1]!) ||
      !alphabet.includes(a) ||
      !alphabet.includes(listArray[i + 1]!)
    ) {
      return this.text;
    }
    if (
      alphabet.indexOf(listArray[i + 1]!) - alphabet.indexOf(a) !== 1 &&
      Math.abs(alphabet.indexOf(listArray[i - 1]!) - alphabet.indexOf(a)) !== 1
    ) {
      return this.text;
    }
    return this.replaceCorrectAlphabetList(a, parens);
  }

  iterateAlphabetArray(regex: RegExp, parens: boolean = false, romanNumeral: boolean = false): string {
    const matches = this.text.match(regex);
    if (!matches) return this.text;

    const alphabet = romanNumeral ? ListItemReplacer.ROMAN_NUMERALS : ListItemReplacer.LATIN_NUMERALS;
    const listArray = matches.filter((i) => alphabet.includes(i));

    listArray.forEach((each, ind) => {
      if (ind === listArray.length - 1) {
        this.text = this.lastArrayItemReplacement(each, ind, alphabet, listArray, parens);
      } else {
        this.text = this.otherItemsReplacement(each, ind, alphabet, listArray, parens);
      }
    });

    return this.text;
  }
}
