import classNames from '@/utils/classnames';
import { ErrorBoundary } from 'react-error-boundary';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Toaster, resolveValue } from 'react-hot-toast';
import * as Sentry from '@sentry/react';
import { SWRConfig, SWRConfiguration } from 'swr';

import { AppRoutes } from './AppRoutes';
import { ErrorFallback } from './components/ErrorFallback';
import { APP_VERSION } from './constants.client';
import { AppProvider } from './contexts/app-context';

const customSwrConfig: SWRConfiguration = {
  revalidateIfStale: true,
  revalidateOnFocus: false,
  revalidateOnReconnect: true,
  revalidateOnMount: true,
  errorRetryCount: 3,
};

export const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(err) => {
        Sentry.captureException(err);
      }}
      onReset={() => {
        window.location.reload();
      }}
    >
      <AppProvider>
        <HelmetProvider>
          <Helmet>
            <link href="/favicon.svg" rel="shortcut icon" type="image/x-icon"></link>
            <meta name="app:version" content={APP_VERSION ?? 'dev'} />
          </Helmet>

          <SWRConfig value={customSwrConfig}>
            <AppRoutes />

            <Toaster position="bottom-right">
              {(t) => (
                <div
                  className={classNames('flex justify-center items-center flex-wrap px-4 py-2', {
                    'opacity-0': !t.visible,
                    'opacity-100': t.visible,
                    'bg-danger-color text-white': t.type === 'error',
                    'bg-green-600 text-white': t.type === 'success',
                    'bg-white text-gray-900 border': t.type !== 'error' && t.type !== 'success',
                  })}
                >
                  {resolveValue(t.message, t)}
                </div>
              )}
            </Toaster>
          </SWRConfig>
        </HelmetProvider>
      </AppProvider>
    </ErrorBoundary>
  );
};
