import { replacePunctuation } from './punctuation_replacer';

export class BetweenPunctuation {
  static readonly BETWEEN_SINGLE_QUOTES_REGEX = /(?<=\s)'(?:[^']|'[a-zA-Z])*'/g;
  static readonly BETWEEN_SINGLE_QUOTE_SLANTED_REGEX = /(?<=\s)'(?:[^']|'[a-zA-Z])*'/g;
  static readonly BETWEEN_DOUBLE_QUOTES_REGEX_2 = /"[^"\\]*(?:\\.[^"\\]*)*"/g;
  static readonly BETWEEN_QUOTE_ARROW_REGEX_2 = /«[^»\\]*(?:\\.[^»\\]*)*»/g;
  static readonly BETWEEN_QUOTE_SLANTED_REGEX_2 = /"[^"\\]*(?:\\.[^"\\]*)*"/g;
  static readonly BETWEEN_SQUARE_BRACKETS_REGEX_2 = /\[([^[\]]*)\]/g;
  static readonly BETWEEN_PARENS_REGEX_2 = /\(([^()]*)\)/g;
  static readonly WORD_WITH_LEADING_APOSTROPHE = /(?<=\s)'(?:[^']|'[a-zA-Z])*'\S/g;
  static readonly BETWEEN_EM_DASHES_REGEX_2 = /--([^-]*)--/g;

  text: string;

  constructor(text: string) {
    this.text = text;
  }

  replace(): string {
    this.text = this.subPunctuationBetweenQuotesAndParens(this.text);
    return this.text;
  }

  subPunctuationBetweenQuotesAndParens(txt: string): string {
    txt = this.subPunctuationBetweenSingleQuotes(txt);
    txt = this.subPunctuationBetweenSingleQuoteSlanted(txt);
    txt = this.subPunctuationBetweenDoubleQuotes(txt);
    txt = this.subPunctuationBetweenSquareBrackets(txt);
    txt = this.subPunctuationBetweenParens(txt);
    txt = this.subPunctuationBetweenQuotesArrow(txt);
    txt = this.subPunctuationBetweenEmDashes(txt);
    txt = this.subPunctuationBetweenQuotesSlanted(txt);

    return txt;
  }

  subPunctuationBetweenParens(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_PARENS_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenSquareBrackets(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_SQUARE_BRACKETS_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenSingleQuotes(txt: string): string {
    if (BetweenPunctuation.WORD_WITH_LEADING_APOSTROPHE.test(this.text) && !/'\s/.test(this.text)) {
      return txt;
    } else {
      return txt.replace(BetweenPunctuation.BETWEEN_SINGLE_QUOTES_REGEX, (match) =>
        replacePunctuation(match, 'single'),
      );
    }
  }

  subPunctuationBetweenSingleQuoteSlanted(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_SINGLE_QUOTE_SLANTED_REGEX, replacePunctuation);
  }

  subPunctuationBetweenDoubleQuotes(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_DOUBLE_QUOTES_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenQuotesArrow(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_QUOTE_ARROW_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenEmDashes(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_EM_DASHES_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenQuotesSlanted(txt: string): string {
    return txt.replace(BetweenPunctuation.BETWEEN_QUOTE_SLANTED_REGEX_2, replacePunctuation);
  }
}
