import { AbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';

class GreekAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

export class Greek extends LanguageBase {
  readonly isoCode = 'el';

  readonly Punctuations = ['.', '!', ';', '?'];

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[.;!?]|.*?$';

  readonly AbbreviationReplacer = GreekAbbreviationReplacer;
}
