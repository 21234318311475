import * as React from 'react';

import createContext from './create-context';
import { useLocalStorage } from '@/hooks/useLocalStorage';

interface ProviderValue {
  enableDebugMode: boolean;
  setEnableDebugMode: (val: boolean) => void;
}

const [useContext, ReactProvider, ReactConsumer] = createContext<ProviderValue>();

interface AppProviderProps {
  children?: React.ReactNode;
}

export const AppProvider: React.FC<AppProviderProps> = (props) => {
  const { children } = props;
  const [enableDebugMode, setEnableDebugMode] = useLocalStorage('debug-mode', false);

  return <ReactProvider value={{ enableDebugMode, setEnableDebugMode }}>{children}</ReactProvider>;
};

export const useAppContext = useContext;
export const AppContextConsumer = ReactConsumer;
