import { useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import diff from 'object-diff';
import { captureException } from '@sentry/react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { JurisdictionSelectField } from './JurisdictionSelect';
import { BodyType as UpdateDocumentPayload } from '../endpoints/UpdateWorkspaceDocumentEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { JurisdictionEnum, LanguageEnum } from '../enums';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { ButtonProps } from '@/components/button/Button';
import { WorkspaceCategoryMultiSelectField } from '@/app/workspaceCategory/components/WorkspaceCategoryMultiSelect';

export interface IBulkDocumentMetadataDialogProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  onComplete?: () => void;
  showTrigger?: boolean;
  triggerText?: React.ReactNode;
  triggerSize?: ButtonProps['size'];
  selectedDocumentIds: string[];
}

export const BulkDocumentMetadataDialog: React.FC<IBulkDocumentMetadataDialogProps> = (props) => {
  const { open, setOpen, showTrigger, triggerText, triggerSize, selectedDocumentIds, onComplete } = props;
  const { tree } = useWorkspace();
  const [isLoading, setIsLoading] = useState(false);

  const initialDocumentData: {
    categoryIds: string[];
    jurisdiction: JurisdictionEnum | null;
    language: LanguageEnum | null;
  } = useMemo(() => {
    return {
      categoryIds: [],
      jurisdiction: null,
      language: null,
    };
  }, [document]);

  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      triggerSize={triggerSize}
      showTrigger={showTrigger}
      triggerText={triggerText}
      title="Document Data"
      submitText="Update"
      isLoading={isLoading}
      isDisabled={!selectedDocumentIds.length}
      onSubmit={async (newValues) => {
        const patch: Partial<typeof newValues> = diff(initialDocumentData, newValues);
        if (Object.keys(patch).length === 0) {
          return;
        }

        setIsLoading(true);

        try {
          let successCount = 0;
          let errorCount = 0;
          let lastError = null;
          for (const documentId of selectedDocumentIds) {
            try {
              const payload: UpdateDocumentPayload = {
                documentId,
                data: {
                  jurisdiction: patch.jurisdiction ? patch.jurisdiction : undefined,
                  categoryIds: patch.categoryIds ?? undefined,
                },
              };
              await fetchEndpointData('/api/v1/workspace/document/update', {
                method: 'POST',
                body: payload,
              });
              successCount += 1;
            } catch (err) {
              captureException(err);
              errorCount += 1;
              toast.error('Could not update document data: ' + getDisplayError(err));
              lastError = err;
            }
          }

          if (successCount > 0) {
            toast.success(`Document data has been updated for ${successCount} documents`);
          }

          if (errorCount > 0) {
            toast.error(`Document data could not be updated for ${errorCount} documents`);
          }

          if (lastError) {
            throw lastError;
          }

          onComplete?.();
        } catch (err) {
          throw err;
        } finally {
          setIsLoading(false);
        }
      }}
      initialValues={initialDocumentData}
    >
      <WorkspaceCategoryMultiSelectField name="categoryIds" labelText="Document Categories" />
      <JurisdictionSelectField name="jurisdiction" labelText="Jurisdiction" />
    </FormDialog>
  );
};
