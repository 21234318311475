import { Rule } from '../../utils';

// Single Letter Abbreviation Rules
const SINGLE_UPPER_CASE_LETTER_START_LINE_RULE = new Rule(/(?<=^[A-Z])\.(?=\s)/, '∯');
const SINGLE_UPPER_CASE_LETTER_RULE = new Rule(/(?<=\s[A-Z])\.(?=,?\s)/, '∯');

// AM/PM Rules
const UPPER_CASE_PM_RULE = new Rule(/(?<= P∯M)∯(?=\s[A-Z])/, '.');
const UPPER_CASE_AM_RULE = new Rule(/(?<=A∯M)∯(?=\s[A-Z])/, '.');
const LOWER_CASE_PM_RULE = new Rule(/(?<=p∯m)∯(?=\s[A-Z])/, '.');
const LOWER_CASE_AM_RULE = new Rule(/(?<=a∯m)∯(?=\s[A-Z])/, '.');

// Number Rules
const PERIOD_BEFORE_NUMBER_RULE = new Rule(/\.(?=\d)/, '∯');
const NUMBER_AFTER_PERIOD_BEFORE_LETTER_RULE = new Rule(/(?<=\d)\.(?=\S)/, '∯');
const NEW_LINE_NUMBER_PERIOD_SPACE_LETTER_RULE = new Rule(/(?<=\r\d)\.(?=(\s\S)|\))/, '∯');
const START_LINE_NUMBER_PERIOD_RULE = new Rule(/(?<=^\d)\.(?=(\s\S)|\))/, '∯');
const START_LINE_TWO_DIGIT_NUMBER_PERIOD_RULE = new Rule(/(?<=^\d\d)\.(?=(\s\S)|\))/, '∯');

// Other Rules
export const POSSESSIVE_ABBREVIATION_RULE = new Rule(/\.(?='s\s)|\.(?='s$)|\.(?='s\Z)/, '∯');
export const KOMMANDITGESELLSCHAFT_RULE = new Rule(/(?<=Co)\.(?=\sKG)/, '∯');

// Rule Collections
export const SINGLE_LETTER_ABBREVIATION_RULES = {
  SingleUpperCaseLetterAtStartOfLineRule: SINGLE_UPPER_CASE_LETTER_START_LINE_RULE,
  SingleUpperCaseLetterRule: SINGLE_UPPER_CASE_LETTER_RULE,
  All: [SINGLE_UPPER_CASE_LETTER_START_LINE_RULE, SINGLE_UPPER_CASE_LETTER_RULE],
};

export const AM_PM_RULES = {
  UpperCasePmRule: UPPER_CASE_PM_RULE,
  UpperCaseAmRule: UPPER_CASE_AM_RULE,
  LowerCasePmRule: LOWER_CASE_PM_RULE,
  LowerCaseAmRule: LOWER_CASE_AM_RULE,
  All: [UPPER_CASE_PM_RULE, UPPER_CASE_AM_RULE, LOWER_CASE_PM_RULE, LOWER_CASE_AM_RULE],
};

export const NUMBER_RULES = {
  PeriodBeforeNumberRule: PERIOD_BEFORE_NUMBER_RULE,
  NumberAfterPeriodBeforeLetterRule: NUMBER_AFTER_PERIOD_BEFORE_LETTER_RULE,
  NewLineNumberPeriodSpaceLetterRule: NEW_LINE_NUMBER_PERIOD_SPACE_LETTER_RULE,
  StartLineNumberPeriodRule: START_LINE_NUMBER_PERIOD_RULE,
  StartLineTwoDigitNumberPeriodRule: START_LINE_TWO_DIGIT_NUMBER_PERIOD_RULE,
  All: [
    PERIOD_BEFORE_NUMBER_RULE,
    NUMBER_AFTER_PERIOD_BEFORE_LETTER_RULE,
    NEW_LINE_NUMBER_PERIOD_SPACE_LETTER_RULE,
    START_LINE_NUMBER_PERIOD_RULE,
    START_LINE_TWO_DIGIT_NUMBER_PERIOD_RULE,
  ],
};
