import { useMemo } from 'react';
import toast from 'react-hot-toast';
import diff from 'object-diff';
import { captureException } from '@sentry/react';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';
import { getDisplayError } from '../../../utils/get-display-error';
import { formatInputDate } from '../../../utils/date';
import { JurisdictionSelectField } from './JurisdictionSelect';
import { BodyType as UpdateDocumentPayload } from '../endpoints/UpdateWorkspaceDocumentEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { WorkspaceCategoryMultiSelectField } from '@/app/workspaceCategory/components/WorkspaceCategoryMultiSelect';

export interface IDocumentMetadataDialogProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  showTrigger?: boolean;
  triggerText?: React.ReactNode;
  document: {
    id: string;
    date?: Date | string | null;
    createdAt: Date | string;
    categories: Array<{ id: string; name: string }>;
    language?: string | null;
    jurisdiction?: string | null;
  };
}

export const DocumentMetadataDialog: React.FC<IDocumentMetadataDialogProps> = (props) => {
  const { open, setOpen, showTrigger, triggerText, document } = props;

  const initialDocumentData = useMemo(() => {
    return {
      date: formatInputDate(document.date ?? document.createdAt),
      categoryIds: (document.categories ?? []).map((v) => v.id),
      jurisdiction: document.jurisdiction,
      language: document.language,
    };
  }, [document]);

  const documentId = document.id;
  return (
    <FormDialog
      open={open}
      setOpen={setOpen}
      showTrigger={showTrigger}
      triggerText={triggerText}
      title="Document Data"
      submitText="Update"
      onSubmit={async (newValues) => {
        const patch: Partial<typeof newValues> = diff(initialDocumentData, newValues);
        if (Object.keys(patch).length === 0) {
          return;
        }

        try {
          const payload: UpdateDocumentPayload = {
            documentId,
            data: {
              ...patch,
              jurisdiction: patch.jurisdiction ? patch.jurisdiction : undefined,
              categoryIds: patch.categoryIds ?? undefined,
            },
          };
          await fetchEndpointData('/api/v1/workspace/document/update', {
            method: 'POST',
            body: payload,
          });

          toast.success('Document data has been updated');
        } catch (err) {
          captureException(err);
          toast.error('Could not update document data: ' + getDisplayError(err));
          throw err;
        }
      }}
      initialValues={initialDocumentData}
    >
      <InputField type="date" labelText="Date" name="date" />
      <WorkspaceCategoryMultiSelectField name="categoryIds" labelText="Document Categories" />
      <JurisdictionSelectField name="jurisdiction" labelText="Jurisdiction" />
    </FormDialog>
  );
};
