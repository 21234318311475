import { AbbreviationReplacer } from '../abbreviation_replacer';
import { Rule } from '../utils';
import { LanguageBase } from './common/Base';

class UrduAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];

  scanForReplacements(txt: string, am: string, index: number, characterArray: string[]): string {
    const escapedAm = am.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`(?<=${escapedAm})\\.`, 'g');
    return txt.replace(regex, '∯');
  }
}

export class Persian extends LanguageBase {
  readonly isoCode = 'fa';

  readonly AbbreviationReplacer = UrduAbbreviationReplacer;

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[:.!?؟]|.*?Z|.*?$';

  readonly Punctuations = ['?', '!', ':', '.', '؟'];

  readonly ReplaceColonBetweenNumbersRule = new Rule(/(?<=\d):(?=\d)/, '♭');
  readonly ReplaceNonSentenceBoundaryCommaRule = new Rule(/،(?=\s\S+،)/, '♬');
}
