import { AbbreviationReplacer as BaseAbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';

class AbbreviationReplacer extends BaseAbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

export class Armenian extends LanguageBase {
  readonly isoCode = 'hy';

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[։՜:]|.*?$';
  readonly Punctuations = ['։', '՜', ':'];

  readonly AbbreviationReplacer = AbbreviationReplacer;
}
