import { AbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

class RussianAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];

  replacePeriodOfAbbr(txt: string, abbr: string): string {
    const cleanAbbr = abbr.trim();
    const escapedAbbr = cleanAbbr.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    txt = txt.replace(new RegExp(`(?<=\\s${escapedAbbr})\\.`, 'g'), '∯');
    txt = txt.replace(new RegExp(`(?<=\\A${escapedAbbr})\\.`, 'g'), '∯');
    txt = txt.replace(new RegExp(`(?<=^${escapedAbbr})\\.`, 'g'), '∯');

    return txt;
  }
}

export class Russian extends LanguageBase {
  readonly isoCode = 'ru';

  readonly AbbreviationReplacer = RussianAbbreviationReplacer;

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'y',
      'y.e',
      'а',
      'авт',
      'адм.-терр',
      'акад',
      'в',
      'вв',
      'вкз',
      'вост.-европ',
      'г',
      'гг',
      'гос',
      'гр',
      'д',
      'деп',
      'дисс',
      'дол',
      'долл',
      'ежедн',
      'ж',
      'жен',
      'з',
      'зап',
      'зап.-европ',
      'заруб',
      'и',
      'ин',
      'иностр',
      'инст',
      'к',
      'канд',
      'кв',
      'кг',
      'куб',
      'л',
      'л.h',
      'л.н',
      'м',
      'мин',
      'моск',
      'муж',
      'н',
      'нед',
      'о',
      'п',
      'пгт',
      'пер',
      'пп',
      'пр',
      'просп',
      'проф',
      'р',
      'руб',
      'с',
      'сек',
      'см',
      'спб',
      'стр',
      'т',
      'тел',
      'тов',
      'тт',
      'тыс',
      'у',
      'у.е',
      'ул',
      'ф',
      'ч',
    ],
    PREPOSITIVE_ABBREVIATIONS: [],
    NUMBER_ABBREVIATIONS: [],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };
}
