import { AbbreviationReplacer } from '../abbreviation_replacer';
import { BetweenPunctuation } from '../between_punctuation';
import { ListItemReplacer } from '../list_item_replacer';
import { Processor } from '../Processor';
import { replacePunctuation } from '../punctuation_replacer';
import { Text } from '../utils';
import { LanguageBase } from './common/Base';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

class SlovakAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];

  replacePeriodOfAbbr(txt: string, abbr: string): string {
    const abbrNew = abbr.replace('.', '∯') + '∯';
    return txt.replace(abbr + '.', abbrNew);
  }
}

class SlovakProcessor extends Processor {
  process(): string[] {
    if (!this.text) {
      return [];
    }
    this.text = this.text.replace('\n', '\r');

    const li = this.lang.ListItemReplacer ? new this.lang.ListItemReplacer(this.text) : new ListItemReplacer(this.text);
    this.text = li.addLineBreak();

    this.replaceAbbreviations();
    this.replaceNumbers();
    this.replacePeriodInDates();
    this.replacePeriodInOrdinalNumerals();
    this.replacePeriodInRomanNumerals();
    this.replaceContinuousPunctuation();
    this.replacePeriodsBeforeNumericReferences();
    this.text = new Text(this.text).apply(
      this.lang.Abbreviation.WithMultiplePeriodsAndEmailRule,
      this.lang.GeoLocationRule,
      this.lang.FileFormatRule,
    );
    return this.splitIntoSegments();
  }

  replacePeriodInOrdinalNumerals(): void {
    this.text = this.text.replace(/(?<=\d)\.(?=\s*[a-z]+)/g, '∯');
  }

  replacePeriodInRomanNumerals(): void {
    this.text = this.text.replace(/((\s+[VXI]+)|(^[VXI]+))(\.)(?=\s+)/gi, '$1∯');
  }
}

class SlovakListItemReplacer extends ListItemReplacer {
  addLineBreak(): string {
    this.formatRomanNumeralLists();
    this.formatNumberedListWithPeriods();
    this.formatNumberedListWithParens();
    return this.text;
  }
}

class SlovakBetweenPunctuation extends BetweenPunctuation {
  BETWEEN_SLOVAK_DOUBLE_QUOTES_REGEX = /„(?:[^"\\]+|\\{2}|\\[^\\])*"/g;
  BETWEEN_SLOVAK_DOUBLE_QUOTES_REGEX_2 = /„(?:[^"\\]+|\\{2}|\\[^\\])*"/g;

  subPunctuationBetweenSlovakDoubleQuotes(txt: string): string {
    return txt.replace(this.BETWEEN_SLOVAK_DOUBLE_QUOTES_REGEX_2, replacePunctuation);
  }

  subPunctuationBetweenQuotesAndParens(txt: string): string {
    txt = this.subPunctuationBetweenSingleQuotes(txt);
    txt = this.subPunctuationBetweenSingleQuoteSlanted(txt);
    txt = this.subPunctuationBetweenDoubleQuotes(txt);
    txt = this.subPunctuationBetweenSquareBrackets(txt);
    txt = this.subPunctuationBetweenParens(txt);
    txt = this.subPunctuationBetweenQuotesArrow(txt);
    txt = this.subPunctuationBetweenEmDashes(txt);
    txt = this.subPunctuationBetweenQuotesSlanted(txt);
    txt = this.subPunctuationBetweenSlovakDoubleQuotes(txt);
    return txt;
  }
}

export class Slovak extends LanguageBase {
  readonly isoCode = 'sk';

  readonly AbbreviationReplacer = SlovakAbbreviationReplacer;
  readonly ListItemReplacer = SlovakListItemReplacer;
  readonly Processor = SlovakProcessor;
  readonly BetweenPunctuation = SlovakBetweenPunctuation;

  readonly MONTHS = [
    'Január',
    'Február',
    'Marec',
    'Apríl',
    'Máj',
    'Jún',
    'Júl',
    'August',
    'September',
    'Október',
    'November',
    'December',
    'Januára',
    'Februára',
    'Marca',
    'Apríla',
    'Mája',
    'Júna',
    'Júla',
    'Augusta',
    'Septembra',
    'Októbra',
    'Novembra',
    'Decembra',
  ];

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'č',
      'no',
      'nr',
      's. r. o',
      'ing',
      'p',
      'a. d',
      'o. k',
      'pol. pr',
      'a. s. a. p',
      'p. n. l',
      'red',
      'o.k',
      'a.d',
      'm.o',
      'pol.pr',
      'a.s.a.p',
      'p.n.l',
      'pp',
      'sl',
      'corp',
      'plgr',
      'tz',
      'rtg',
      'o.c.p',
      'o. c. p',
      'c.k',
      'c. k',
      'n.a',
      'n. a',
      'a.m',
      'a. m',
      'vz',
      'i.b',
      'i. b',
      'ú.p.v.o',
      'ú. p. v. o',
      'bros',
      'rsdr',
      'doc',
      'tu',
      'ods',
      'n.w.a',
      'n. w. a',
      'nár',
      'pedg',
      'paeddr',
      'rndr',
      'naprk',
      'a.g.p',
      'a. g. p',
      'prof',
      'pr',
      'a.v',
      'a. v',
      'por',
      'mvdr',
      'nešp',
      'u.s',
      'u. s',
      'kt',
      'vyd',
      'e.t',
      'e. t',
      'al',
      'll.m',
      'll. m',
      'o.f.i',
      'o. f. i',
      'mr',
      'apod',
      'súkr',
      'stred',
      's.e.g',
      's. e. g',
      'sr',
      'tvz',
      'ind',
      'var',
      'etc',
      'atd',
      'n.o',
      'n. o',
      's.a',
      's. a',
      'např',
      'a.i.i',
      'a. i. i',
      'a.k.a',
      'a. k. a',
      'konkr',
      'čsl',
      'odd',
      'ltd',
      't.z',
      't. z',
      'o.z',
      'o. z',
      'obv',
      'obr',
      'pok',
      'tel',
      'št',
      'skr',
      'phdr',
      'xx',
      'š.p',
      'š. p',
      'ph.d',
      'ph. d',
      'm.n.m',
      'm. n. m',
      'zz',
      'roz',
      'atď.',
      'ev',
      'v.sp',
      'v. sp',
      'drsc',
      'mudr',
      't.č',
      't. č',
      'el',
      'os',
      'co',
      'r.o',
      'r. o',
      'str',
      'p.a',
      'p. a',
      'zdravot',
      'prek',
      'gen',
      'viď',
      'dr',
      'cca',
      'p.s',
      'p. s',
      'zák',
      'slov',
      'arm',
      'inc',
      'max',
      'd.c',
      'k.o',
      'a. r. k',
      'd. c',
      'k. o',
      'a. r. k',
      'soc',
      'bc',
      'zs',
      'akad',
      'sz',
      'pozn',
      'tr',
      'nám',
      'kol',
      'csc',
      'ul',
      'sp',
      'o.i',
      'jr',
      'zb',
      'sv',
      'tj',
      'čs',
      'tzn',
      'príp',
      'iv',
      'hl',
      'st',
      'pod',
      'vi',
      'tis',
      'stor',
      'rozh',
      'mld',
      'atď',
      'mgr',
      'a.s',
      'a. s',
      'phd',
      'z.z',
      'z. z',
      'judr',
      'ing',
      'hod',
      'vs',
      'písm',
      's.r.o',
      'min',
      'ml',
      'iii',
      't.j',
      't. j',
      'spol',
      'mil',
      'ii',
      'napr',
      'resp',
      'tzv',
    ],
    PREPOSITIVE_ABBREVIATIONS: ['st', 'p', 'dr', 'mudr', 'judr', 'ing', 'mgr', 'bc', 'drsc', 'doc', 'prof'],
    NUMBER_ABBREVIATIONS: ['č', 'no', 'nr'],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };
}
