import { useEffect, useState } from 'react';
import { createId } from '@paralleldrive/cuid2';

import { BaseButton } from '../../../components/button/BaseButton';
import { useWebsocket } from '../../../contexts/websocket-context';
import { LanguageEnum } from '../enums';
import { DisposableStore } from '../../../utils/disposable';
import { ChatMessageText } from '../../workspaceChat/pages/ChatMessage';
import { SpinnerBlock } from '../../../components/Spinner';
import { DialogContent, DialogRoot, DialogTrigger } from '../../../components/dialog/Dialog';
import { useTeam } from '@/app/team/context/TeamContext';

export interface ISummarizeDialogProps {
  title: string;
  documentId: string;
  language: string;
}

export function SummarizeDialog(props: ISummarizeDialogProps) {
  const { title, documentId, language } = props;
  const [open, setOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const { websocket: wsClient } = useWebsocket();
  const [msgRef] = useState(createId());
  const [content, setContent] = useState('');
  const { team } = useTeam();

  useEffect(() => {
    const disposableStore = new DisposableStore();

    disposableStore.add(
      wsClient.onMessage((message) => {
        if (message.ref === msgRef) {
          if (message.method === 'workspace/document/summarize-response') {
            setContent(message.data.content);

            if (message.data.isFinished) {
              setIsFetching(false);
              disposableStore.dispose();
            }
          }
        }
      }),
    );

    disposableStore.add(
      wsClient.onErrorMessage((message) => {
        setContent(`Error: ${message.error.message}`);

        if (message.ref === msgRef) {
          setIsFetching(false);
          disposableStore.dispose();
        }
      }),
    );

    return () => disposableStore.dispose();
  }, [true]);

  useEffect(() => {
    if (open && !isFetching && !content) {
      setIsFetching(true);

      wsClient.send({
        ref: msgRef,
        method: 'workspace/document/summarize-request',
        data: {
          documentId,
          language: language.toLowerCase() as LanguageEnum,
        },
      });
    }
  }, [open, isFetching]);

  return (
    <DialogRoot open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <BaseButton
          isLoading={isFetching}
          onClick={() => {
            setOpen(true);
          }}
        >
          Summarize
        </BaseButton>
      </DialogTrigger>

      <DialogContent className="dialog-content">
        <h1 className="heading-one mb-4">Summary of {title}</h1>

        <div className="overflow-y-auto" style={{ height: '50vh' }}>
          {Boolean(!content && isFetching) ? (
            <SpinnerBlock message="Generating summary..." />
          ) : (
            <ChatMessageText content={content} references={[]} documents={{}} openRef={() => {}} />
          )}
        </div>
      </DialogContent>
    </DialogRoot>
  );
}
