import { useField } from 'formik';
import React, { useMemo } from 'react';

import { ISimpleSelectItem, SimpleSelect } from '../../../components/select/SimpleSelect';
import { InputWrapper } from '../../../components/InputWrapper';
import { ICategoryNode } from '../hooks/useCategories';

export interface ICategorySelectFieldProps {
  labelText: string;
  name: string;
  helperText?: string;
  isDisabled?: boolean;
  excludedCategoryIds?: string[];
  onlyTopLevel?: boolean;
  categoryTree: ICategoryNode[];
}

export const CategorySelectField: React.FC<ICategorySelectFieldProps> = (props) => {
  const { name, labelText, helperText, isDisabled, excludedCategoryIds, onlyTopLevel, categoryTree } = props;
  const [field, meta, helpers] = useField({ name });

  const items = useMemo(() => {
    const result: ISimpleSelectItem[] = [];
    const addNode = (idx: string, parentIdx: string, node: ICategoryNode) => {
      if (excludedCategoryIds?.includes(node.id)) return;

      if (onlyTopLevel && parentIdx) return;

      const fullIdx = parentIdx ? `${parentIdx}.${idx}` : idx;
      result.push({ key: node.id, name: `${fullIdx} ${node.name}` });
      for (let i = 0; i < node.children.length; i++) {
        addNode(`${i + 1}`, fullIdx, node.children[i]!);
      }
    };
    for (let i = 0; i < categoryTree.length; i++) {
      addNode(`${i + 1}`, '', categoryTree[i]!);
    }
    return result;
  }, [categoryTree]);

  return (
    <InputWrapper
      labelText={labelText}
      invalidText={meta.touched ? meta.error : undefined}
      helperText={helperText}
      noLabel
    >
      <SimpleSelect
        items={items}
        selectedItem={field.value ? (items.find((v) => v.key === field.value) ?? null) : null}
        onSelect={(value: ISimpleSelectItem | null) => {
          helpers.setValue(value?.key ?? null);
        }}
        onBlur={() => helpers.setTouched(true)}
        isDisabled={isDisabled}
        isInvalid={Boolean(meta.touched && meta.error)}
        isOptional={true}
      />
    </InputWrapper>
  );
};
