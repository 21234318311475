import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import React, { useCallback, useEffect, useId, useMemo, useState } from 'react';
import classNames from '@/utils/classnames';
import { toast } from 'react-hot-toast';
import {
  Bug as BugIcon,
  Clipboard as ClipboardIcon,
  DownloadSimple as DownloadIcon,
  Eye as EyeIcon,
  ChatCircle as MessageCircleIcon,
  PencilSimple as PencilIcon,
  NotePencil as NotePencilIcon,
  Trash as TrashIcon,
  User as UserIcon,
  Building as BuildingIcon,
  TextColumns as TextColumnsIcon,
  Money as MoneyIcon,
} from '@phosphor-icons/react';
import useSWR from 'swr';
import { captureException } from '@sentry/react';

import { PageHeader } from '../../../components/PageHeader';
import { nullthrows } from '../../../utils/invariant';
import { IChildNode, generateDocumentContentTree } from './utils/content-tree.util';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { ConfirmDialog } from '../../../components/dialog/ConfirmDialog';
import { getDisplayError } from '../../../utils/get-display-error';
import { FilePreview, PREVIEW_MIMETYPES } from '../../file/components/FilePreview';
import { useAuth } from '../../../contexts/auth-context';
import { Button, LinkButton } from '../../../components/button/Button';
import { Tag } from '../../../components/Tag';
import { formatDate, formatDateTime } from '../../../utils/date';
import { SummarizeDialog } from '../components/SummaryDialog';
import { InputDialog } from '../../../components/dialog/InputDialog';
import { DocumentMetadataDialog } from '../components/DocumentMetadataDialog';
import { StatusDot } from '../../../components/StatusDot';
import { SpinnerBlock } from '../../../components/Spinner';
import { BodyType as DeleteDocumentsPayload } from '../endpoints/DeleteWorkspaceDocumentsEndpoint';
import { BodyType as RenameDocumentPayload } from '../endpoints/RenameDocumentEndpoint';
import { ResponseType as DocumentContentResponseType } from '../endpoints/DocumentContentEndpoint';
import { ResponseType as DocumentMetadataResponseType } from '../endpoints/DocumentMetadataEndpoint';
import { ResponseType as DocumentChunksResponseType } from '../endpoints/DocumentChunksEndpoint';
import { ResponseType as SignedUrlResponseType } from '../../file/endpoints/SignedFileUrlEndpoint';
import {
  ResponseType as DocumentParagraphIndexesResponseType,
  BodyType as DocumentParagraphIndexesPayload,
} from '../endpoints/WorkspaceDocumentParagraphIndexesEndpoint';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { DocumentIndexingStatus } from '../enums';
import { useWorkspace } from '@/app/workspace/context/WorkspaceContext';
import { TreeNode } from '../tree/WorkspaceDocumentTree';
import { WorkspacePermissionEnum } from '@/app/auth/enum';
import { WorkspacePermissionWrapper } from '@/app/workspace/components/WorkspacePermissionWrapper';
import { DocumentTreeBreadcrumb } from '../components/DocumentTreeBreadcrumb';
import { useTeam } from '@/app/team/context/TeamContext';
import { InfoDialog } from '@/components/dialog/InfoDialog';

function headingLevelToTag(level: number) {
  switch (level) {
    case 1:
      return 'h1';
    case 2:
      return 'h2';
    case 3:
      return 'h3';
    case 4:
      return 'h4';
    case 5:
      return 'h5';
    default:
      return 'h6';
  }
}

export interface IDocumentContentProps {
  content: string;
  paragraphIndexes?: number[];
  paragraphsToHighlight?: number[];
}

export const DocumentNode: React.FC<{ node: IChildNode; baseKey: string; isHighlighted: boolean }> = (props) => {
  const { node, baseKey, isHighlighted } = props;

  if (node.type === 'paragraph') {
    return (
      <p className="my-1 whitespace-pre-line">
        {node.children.map((c, i) => (
          <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
        ))}
      </p>
    );
  } else if (node.type === 'heading') {
    return React.createElement(
      headingLevelToTag(node.level),
      {
        className: 'doc-heading',
      },
      node.children.map((c, i) => (
        <DocumentNode node={c} baseKey={`${baseKey}-${i}`} key={`${baseKey}-${i}`} isHighlighted={isHighlighted} />
      )),
    );
  } else if (node.type === 'table') {
    return (
      <table className="table-auto my-4 rounded-md overflow-hidden">
        {node.rows.map((row, rowIdx) => {
          return (
            <tr
              className={classNames('border-b', {
                'bg-dark-06': row.columns.find((col) => col.type === 'th'),
              })}
              key={`${baseKey}-${rowIdx}`}
            >
              {row.columns.map((col, colIdx) => {
                const colKey = `${baseKey}-${rowIdx}-${colIdx}`;

                if (col.type === 'td') {
                  return (
                    <td
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </td>
                  );
                } else {
                  return (
                    <th
                      key={colKey}
                      colSpan={col.colspan}
                      className={classNames('p-2 border-b', {
                        'border-r': colIdx !== row.columns.length - 1,
                      })}
                    >
                      {col.children.map((c, i) => {
                        return (
                          <DocumentNode
                            node={c}
                            baseKey={`${baseKey}-${i}`}
                            key={`${colKey}-${i}`}
                            isHighlighted={isHighlighted}
                          />
                        );
                      })}
                    </th>
                  );
                }
              })}
            </tr>
          );
        })}
      </table>
    );
  } else if (node.type === 'list') {
    const TagType = node.isOrdered ? 'ol' : 'ul';
    return (
      <TagType className="my-4">
        {node.items.map((item, itemIdx) => {
          const liKey = `${baseKey}-${itemIdx}`;
          return (
            <li
              className={classNames('ml-4', {
                'list-decimal': node.isOrdered,
                'list-disc': !node.isOrdered,
              })}
              key={liKey}
            >
              {item.children.map((c, i) => (
                <DocumentNode
                  node={c}
                  baseKey={`${baseKey}-${i}`}
                  key={`${liKey}-${i}`}
                  isHighlighted={isHighlighted}
                />
              ))}
            </li>
          );
        })}
      </TagType>
    );
  } else if (node.type === 'text') {
    return (
      <span
        className={classNames({
          'font-bold': node.isBold,
          'font-italic': node.isItalic,
          'bg-yellow-fluo': isHighlighted,
        })}
      >
        {node.content}
      </span>
    );
  } else {
    console.error('Unhandled node type', node);
    return null;
  }
};

export const DocumentDebugView: React.FC<{ document: DocumentMetadataResponseType['document'] }> = (props) => {
  const { document } = props;
  const { data: chunksData, isLoading } = useSWR<DocumentChunksResponseType>(
    `/api/v1/document/chunks/${document.id}`,
    fetchEndpointData,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateIfStale: false,
    },
  );

  if (isLoading) {
    return <SpinnerBlock message="Loading debug data..." />;
  }

  const chunks = chunksData?.chunks ?? [];
  return (
    <div className="flex flex-col gap-4">
      <div className="card flex gap-2 flex-wrap">
        <Tag color="blue">{`Confidence ${Math.round((document.ocrConfidence ?? 1) * 100)}%`}</Tag>
        <Tag color="blue">{`Created at ${formatDateTime(document.createdAt)}`}</Tag>
      </div>

      {chunks
        .sort((a, b) => a.chunkIdx - b.chunkIdx)
        .map((c) => {
          return (
            <div key={c.id} className="card">
              <div className="flex justify-between items-center mb-4">
                <div className="font-bold">{`Chunk #${c.chunkIdx} - Group ${c.groupIdx ?? '-'}`}</div>
                <div>
                  <Button
                    onTrigger={() => {
                      navigator.clipboard.writeText(c.content);
                      toast.success('Copied to clipboard');
                    }}
                  >
                    <ClipboardIcon className="button-icon" />
                  </Button>
                </div>
              </div>
              <div className="whitespace-pre-line">{c.content}</div>
            </div>
          );
        })}
    </div>
  );
};

export const DocumentContent: React.FC<IDocumentContentProps> = (props) => {
  const { content, paragraphIndexes = [], paragraphsToHighlight = [] } = props;

  const id = useId();
  const nodes = useMemo(() => {
    return generateDocumentContentTree(content);
  }, [content, paragraphIndexes.sort().join(',')]);

  useEffect(() => {
    if (paragraphsToHighlight.length) {
      const firstHighlighted = paragraphsToHighlight[0];
      const element = document.getElementById(`${id}-${firstHighlighted}`);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [paragraphsToHighlight]);

  const highlightSet = new Set(paragraphsToHighlight);
  const filterSet = new Set(paragraphIndexes);
  return (
    <>
      {nodes.map((node, nodeIdx) => {
        if (filterSet.has(nodeIdx)) {
          return null;
        }

        const baseKey = `${id}-${nodeIdx}`;
        if (highlightSet.has(nodeIdx)) {
          return (
            <div key={baseKey} id={baseKey}>
              <DocumentNode node={node} baseKey={baseKey} isHighlighted={true} />
            </div>
          );
        }

        return <DocumentNode node={node} baseKey={baseKey} key={baseKey} isHighlighted={false} />;
      })}
    </>
  );
};

const fetchParagraphIndexes = async ([endpoint, chunkIds]: [string, string[]]) => {
  const payload: DocumentParagraphIndexesPayload = {
    chunkIds,
  };
  const result = await fetchEndpointData<DocumentParagraphIndexesResponseType>(endpoint, {
    method: 'POST',
    body: payload,
  });
  return result;
};

export interface IDocumentComponentProps {
  document: DocumentMetadataResponseType['document'];
  documentContent: string | null;
  folderNode: TreeNode;
}

const DocumentComponent: React.FC<IDocumentComponentProps> = (props) => {
  const { document, documentContent } = props;
  const [searchParams] = useSearchParams();
  const { team } = useTeam();
  const { workspace } = useWorkspace();
  const [debugMode, setDebugMode] = useState(false);
  const { me } = useAuth();
  const navigate = useNavigate();

  const documentId = document.id;
  const file = document.file;
  const pageNumber = searchParams.get('pageNumber');

  const highlightChunks = searchParams.get('highlightChunks');
  const { data: paragraphsToHighlightData } = useSWR(
    [`/api/v1/workspace/document/paragraph-indexes/${document.id}`, highlightChunks ? highlightChunks.split(';') : []],
    fetchParagraphIndexes,
  );
  const paragraphsToHighlight = paragraphsToHighlightData?.parahraphIndexes || [];

  const { data: signedFileLinkRes } = useSWR<SignedUrlResponseType>(
    `/api/v1/file/signed-url/${file?.id}`,
    fetchEndpointData,
    {
      isPaused: useCallback(() => !file, [file]),
    },
  );

  const fileUrl = signedFileLinkRes?.signedUrl;
  const hasPreviews = Boolean(file && PREVIEW_MIMETYPES.has(file.mimetype));
  const [showPreview, setShowPreview] = useState(false);
  const [shownDialog, setShownDialog] = useState('none');

  const isProcessing = document.indexingStatus !== DocumentIndexingStatus.Indexed;
  const content = documentContent ?? '';
  return (
    <div className="page-content">
      <PageHeader title={document.name} />

      <div className="flex justify-between items-center max-w-full gap-4 mb-4">
        <Breadcrumb
          items={[
            {
              name: 'Document',
            },
          ]}
        />

        <div className="flex gap-2 items-center">
          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.Summarize]}>
            <SummarizeDialog title={document.name} documentId={documentId} language={me.language} />
          </WorkspacePermissionWrapper>
          {me.isSuperUser && (
            <Button onTrigger={() => setDebugMode(!debugMode)}>
              <BugIcon className="button-icon" />
            </Button>
          )}
          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.WriteDocuments]}>
            <DocumentMetadataDialog
              triggerText={<NotePencilIcon className="button-icon" />}
              document={{
                ...document,
                categories: document.categories.map((v) => v.category),
              }}
              key={document.id}
            />
          </WorkspacePermissionWrapper>
          <WorkspacePermissionWrapper allowedPermissions={[WorkspacePermissionEnum.DeleteDocuments]}>
            <ConfirmDialog
              triggerText={<TrashIcon className="button-icon" />}
              title="Delete document"
              submitText="Delete"
              triggerVariant="destructive"
              description={`Are you sure you want to delete ${document.name}?`}
              onSubmit={async () => {
                try {
                  const payload: DeleteDocumentsPayload = {
                    documentIds: [documentId],
                    workspaceId: workspace.id,
                  };
                  await fetchEndpointData('/api/v1/workspace/document/delete', {
                    method: 'DELETE',
                    body: payload,
                  });
                  toast.success('Document has been deleted');
                  navigate('..');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not delete document: ' + getDisplayError(err));
                }
              }}
            />
          </WorkspacePermissionWrapper>
        </div>
      </div>

      <div className="mb-4">
        <DocumentTreeBreadcrumb
          nodeId={document.id}
          onSelect={(nodeId: string) => {
            if (nodeId === document.id) {
              return;
            }

            navigate(`/app/t/${team.id}/workspace/${workspace.id}/documents/${nodeId}`);
          }}
        />
      </div>

      <InfoDialog
        title="Parties"
        hideTrigger={true}
        isOpen={shownDialog === 'parties'}
        setIsOpen={(newOpen) => {
          if (!newOpen) {
            setShownDialog('none');
          } else {
            setShownDialog('parties');
          }
        }}
      >
        <div className="flex flex-col gap-2">
          {document.parties.map((party) => {
            return (
              <div key={party.id} className="flex items-center gap-2">
                {party.isPerson ? <UserIcon className="w-4 h-4" /> : <BuildingIcon className="w-4 h-4" />}
                <div>{party.name}</div>
                <div className="flex flex-wrap gap-2">
                  {party.isPerson && party.companyName && <Tag color="orange">{party.companyName}</Tag>}

                  {party.roles.map((role, roleIdx) => {
                    return (
                      <Tag color="blue" key={`${party.id}-role-${roleIdx}`}>
                        {role}
                      </Tag>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </InfoDialog>

      <InfoDialog
        title="Table of Contents"
        hideTrigger={true}
        isOpen={shownDialog === 'table-of-contents'}
        setIsOpen={(newOpen) => {
          if (!newOpen) {
            setShownDialog('none');
          } else {
            setShownDialog('table-of-contents');
          }
        }}
      >
        <div className="flex flex-col gap-2">
          {document.tableOfContents.map((line, idx) => {
            return <div key={idx}>{line}</div>;
          })}
        </div>
      </InfoDialog>

      {debugMode ? (
        <div>
          <DocumentDebugView document={document} />
        </div>
      ) : (
        <div className="card">
          <div className="flex justify-between items-center flex-wrap mb-4">
            <div className="flex items-center gap-2">
              {isProcessing && (
                <div className="flex items-center gap-1">
                  <StatusDot size={3} color="blue" pulse={true} />
                  Processing
                </div>
              )}
              <Tag color="blue">{formatDate(document.date ?? document.createdAt)}</Tag>
              {document.categories.map((c) => {
                return (
                  <Tag key={c.category.id} color="green">
                    {c.category.name}
                  </Tag>
                );
              })}
              {document.jurisdiction && <Tag color="red">{document.jurisdiction.toUpperCase()}</Tag>}
              {document.language && <Tag color="red">{document.language.toUpperCase()}</Tag>}
              {document.parties.length > 0 && (
                <Tag
                  color="blue"
                  onClick={() => {
                    setShownDialog('parties');
                  }}
                >
                  <UserIcon className="w-4 h-4 mr-1" />
                  {`${document.parties.length}`}
                </Tag>
              )}
              {document.tableOfContents.length > 0 && (
                <Tag
                  color="blue"
                  onClick={() => {
                    setShownDialog('table-of-contents');
                  }}
                >
                  <TextColumnsIcon className="w-4 h-4 mr-1" />
                  {`${document.tableOfContents.length}`}
                </Tag>
              )}
              {document.contractValue && (
                <Tag
                  color="blue"
                  onClick={() => {
                    setShownDialog('parties');
                  }}
                >
                  <MoneyIcon className="w-4 h-4 mr-1" />
                  {`${document.contractValue} ${document.contractCurrency}`}
                </Tag>
              )}
            </div>

            <div className="flex items-center gap-2">
              <LinkButton title="Chat with document" to={`../../../chat?documents=${document.id}`}>
                <MessageCircleIcon className="button-icon" />
              </LinkButton>
              {hasPreviews && (
                <Button title="Toggle Preview" onTrigger={() => setShowPreview(!showPreview)}>
                  <EyeIcon className="button-icon" />
                </Button>
              )}
              {fileUrl && (
                <LinkButton isExternal to={fileUrl} target="_blank">
                  <DownloadIcon className="button-icon" />
                </LinkButton>
              )}
            </div>
          </div>

          <div className="flex items-center gap-2 mb-8">
            <div className="heading-one">{document.name}</div>

            <InputDialog
              triggerText={<PencilIcon className="button-icon" />}
              triggerVariant="ghost"
              title="Rename document"
              description="Enter a new name for the document"
              labelText="New name"
              submitText="Rename"
              initialValue={document.name}
              onSubmit={async (value) => {
                try {
                  const payload: RenameDocumentPayload = {
                    documentId,
                    newName: value,
                  };
                  await fetchEndpointData('/api/v1/workspace/document/rename', {
                    method: 'POST',
                    body: payload,
                  });
                  toast.success('Document name has been updated');
                } catch (err) {
                  captureException(err);
                  toast.error('Could not update document name: ' + getDisplayError(err));
                }
              }}
            />
          </div>

          {isProcessing && !content && <SpinnerBlock message="Processing document..." />}

          {showPreview && hasPreviews && fileUrl && file ? (
            <FilePreview file={file} fileUrl={fileUrl} pageNumber={pageNumber ? +pageNumber : null} />
          ) : (
            <DocumentContent content={content} paragraphsToHighlight={paragraphsToHighlight} />
          )}
        </div>
      )}
    </div>
  );
};

export const WorkspaceDocumentPage = () => {
  const { documentId: _documentId, folderId: _folderId } = useParams<{
    documentId: string;
    folderId: string;
  }>();
  const documentId = nullthrows(_documentId, 'documentId not defined in params');
  const folderId = nullthrows(_folderId, 'folderId not defined in params');

  const {
    data: documentData,
    isLoading,
    mutate: mutateDocMetadata,
  } = useSWR<DocumentMetadataResponseType>(`/api/v1/workspace/document/metadata/${documentId}`, fetchEndpointData, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const {
    data: documentContentData,
    isLoading: isLoadingContent,
    mutate: mutateDocContent,
  } = useSWR<DocumentContentResponseType>(`/api/v1/workspace/document/content/${documentId}`, fetchEndpointData, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateIfStale: false,
  });
  const isFetching = isLoading || isLoadingContent;

  const { tree: explorerTree } = useWorkspace();

  const content = documentContentData?.content;
  const refetchDocument = useCallback(() => {
    if (!content) {
      mutateDocContent(undefined, {
        revalidate: true,
      });
    }

    mutateDocMetadata(undefined, {
      revalidate: true,
    });
  }, [mutateDocMetadata, content]);

  useEffect(() => {
    const getUpdatedAt = () => {
      const node = explorerTree.getDocumentNode(documentId);
      if (!node) {
        return 0;
      }

      const nodeUpdatedAt = node.updatedAt.getTime();
      const documentUpdatedAt = node.document?.updatedAt.getTime() ?? 0;
      return Math.max(nodeUpdatedAt, documentUpdatedAt);
    };
    let lastUpdatedAt = getUpdatedAt();
    const disposable = explorerTree.onTreeChange(() => {
      const updatedAt = getUpdatedAt();
      if (updatedAt !== lastUpdatedAt) {
        lastUpdatedAt = updatedAt;
        refetchDocument();
      }
    });
    return () => {
      disposable.dispose();
    };
  }, [explorerTree]);

  const document = documentData?.document;
  const folderNode = explorerTree.getFolderNode(folderId);
  if (!document || !folderNode) {
    if (isFetching || explorerTree.isSyncing) {
      return <SpinnerBlock message="Loading document..." />;
    } else {
      if (!document) {
        throw new Error('Document not found');
      }

      if (!folderNode) {
        throw new Error('Folder not found');
      }
    }
  }

  return (
    <DocumentComponent
      document={document}
      documentContent={documentContentData?.content || ''}
      folderNode={folderNode}
    />
  );
};
