import { AbbreviationReplacer as BaseAbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

class AbbreviationReplacer extends BaseAbbreviationReplacer {
  SENTENCE_STARTERS = [];

  replacePeriodOfAbbr(txt: string, abbr: string): string {
    const cleanAbbr = abbr.trim();
    return txt.replace(new RegExp(`(?<=\\s${cleanAbbr})\\.|(?<=^${cleanAbbr})\\.`, 'gu'), '∯');
  }
}

export class Bulgarian extends LanguageBase {
  readonly isoCode = 'bg';

  readonly AbbreviationReplacer = AbbreviationReplacer;

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'p.s',
      'акад',
      'ал',
      'б.р',
      'б.ред',
      'бел.а',
      'бел.пр',
      'бр',
      'бул',
      'в',
      'вж',
      'вкл',
      'вм',
      'вр',
      'г',
      'ген',
      'гр',
      'дж',
      'дм',
      'доц',
      'др',
      'ем',
      'заб',
      'зам',
      'инж',
      'к.с',
      'кв',
      'кв.м',
      'кг',
      'км',
      'кор',
      'куб',
      'куб.м',
      'л',
      'лв',
      'м',
      'м.г',
      'мин',
      'млн',
      'млрд',
      'мм',
      'н.с',
      'напр',
      'пл',
      'полк',
      'проф',
      'р',
      'рис',
      'с',
      'св',
      'сек',
      'см',
      'сп',
      'срв',
      'ст',
      'стр',
      'т',
      'т.г',
      'т.е',
      'т.н',
      'т.нар',
      'табл',
      'тел',
      'у',
      'ул',
      'фиг',
      'ха',
      'хил',
      'ч',
      'чл',
      'щ.д',
    ],
    PREPOSITIVE_ABBREVIATIONS: [],
    NUMBER_ABBREVIATIONS: [],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };
}
