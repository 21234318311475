import { AbbreviationReplacer } from '../abbreviation_replacer';
import { BetweenPunctuation } from '../between_punctuation';
import { Cleaner } from '../Cleaner';
import { replacePunctuation } from '../punctuation_replacer';
import { Rule, Text } from '../utils';
import { LanguageBase } from './common/Base';

class JapaneseAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

class JapaneseBetweenPunctuation extends BetweenPunctuation {
  replace(): string {
    this.text = this.subPunctuationBetweenQuotesAndParens(this.text);
    return this.text;
  }

  subPunctuationBetweenParensJa(txt: string): string {
    // Updated regex to use standard backreference syntax
    const BETWEEN_PARENS_JA_REGEX = /（([^（）]+|\\{2}|\\.)*）/g;
    return txt.replace(BETWEEN_PARENS_JA_REGEX, replacePunctuation);
  }

  subPunctuationBetweenQuotesJa(txt: string): string {
    // Updated regex to use standard backreference syntax
    const BETWEEN_QUOTE_JA_REGEX = /「([^「」]+|\\{2}|\\.)*」/g;
    return txt.replace(BETWEEN_QUOTE_JA_REGEX, replacePunctuation);
  }

  subPunctuationBetweenQuotesAndParens(txt: string): string {
    txt = this.subPunctuationBetweenParensJa(txt);
    txt = this.subPunctuationBetweenQuotesJa(txt);
    return txt;
  }
}

class JapaneseCleaner extends Cleaner {
  clean(): string {
    this.removeNewlineInMiddleOfWord();
    return this.text;
  }

  removeNewlineInMiddleOfWord(): void {
    const NewLineInMiddleOfWordRule = new Rule(/(?<=の)\n(?=\S)/, '');
    this.text = new Text(this.text).apply(NewLineInMiddleOfWordRule);
  }
}

export class Japanese extends LanguageBase {
  readonly isoCode = 'ja';

  readonly AbbreviationReplacer = JapaneseAbbreviationReplacer;

  readonly BetweenPunctuation = JapaneseBetweenPunctuation;

  readonly Cleaner = JapaneseCleaner;
}
