import React from 'react';
import { FormikHelpers } from 'formik';

import { FormDialog } from '../../../components/dialog/FormDialog';
import { InputField } from '../../../components/input/InputField';

interface ICategoryTemplateFormValues {
  name: string;
}

interface ICategoryTemplateFormProps {
  isOpen?: boolean;
  setIsOpen?: (newIsOpen: boolean) => void;
  title: string;
  submitText: string;
  onSubmit: (value: ICategoryTemplateFormValues, helpers: FormikHelpers<ICategoryTemplateFormValues>) => Promise<void>;
  initialValues?: {
    name: string;
  };
}

export const CategoryTemplateFormDialog: React.FC<ICategoryTemplateFormProps> = (props) => {
  const { isOpen, setIsOpen, title, submitText, onSubmit, initialValues } = props;

  return (
    <FormDialog
      open={isOpen}
      setOpen={setIsOpen}
      showTrigger={false}
      triggerText=""
      title={title}
      submitText={submitText}
      onSubmit={onSubmit}
      initialValues={
        initialValues ?? {
          name: '',
        }
      }
    >
      <InputField type="text" labelText="Name" name="name" />
    </FormDialog>
  );
};
