import { replacePunctuation } from './punctuation_replacer';

export class ExclamationWords {
  private static readonly EXCLAMATION_WORDS = [
    '!Xũ',
    '!Kung',
    'ǃʼOǃKung',
    '!Xuun',
    '!Kung-Ekoka',
    'ǃHu',
    'ǃKhung',
    'ǃKu',
    'ǃung',
    'ǃXo',
    'ǃXû',
    'ǃXung',
    'ǃXũ',
    '!Xun',
    'Yahoo!',
    'Y!J',
    'Yum!',
  ];

  private static readonly EXCLAMATION_REGEX = new RegExp(
    ExclamationWords.EXCLAMATION_WORDS.map((w) => w.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|'),
  );

  static applyRules(text: string): string {
    return text.replace(ExclamationWords.EXCLAMATION_REGEX, replacePunctuation);
  }
}
