import { English } from './lang/English';
import { Hindi } from './lang/Hindi';
import { Marathi } from './lang/Marathi';
import { Chinese } from './lang/Chinese';
import { Spanish } from './lang/Spanish';
import { Amharic } from './lang/Amharic';
import { Arabic } from './lang/Arabic';
import { Armenian } from './lang/Armenian';
import { Bulgarian } from './lang/Bulgarian';
import { Urdu } from './lang/Urdu';
import { Russian } from './lang/Russian';
import { Polish } from './lang/Polish';
import { Persian } from './lang/Persian';
import { Dutch } from './lang/Dutch';
import { LanguageBase } from './lang/common/Base';
import { Danish } from './lang/Danish';
import { French } from './lang/French';
import { Burmese } from './lang/Burmese';
import { Greek } from './lang/Greek';
import { Italian } from './lang/Italian';
import { Japanese } from './lang/Japanese';
import { German } from './lang/German';
import { Kazakh } from './lang/Kazakh';
import { Slovak } from './lang/Slovak';

const LANGUAGE_CODES: { [key: string]: any } = {
  en: English,
  hi: Hindi,
  mr: Marathi,
  zh: Chinese,
  es: Spanish,
  am: Amharic,
  ar: Arabic,
  hy: Armenian,
  bg: Bulgarian,
  ur: Urdu,
  ru: Russian,
  pl: Polish,
  fa: Persian,
  nl: Dutch,
  da: Danish,
  fr: French,
  my: Burmese,
  el: Greek,
  it: Italian,
  ja: Japanese,
  de: German,
  kk: Kazakh,
  sk: Slovak,
};

export function getLanguage(code: string): LanguageBase {
  if (code in LANGUAGE_CODES) {
    return new LANGUAGE_CODES[code]();
  } else {
    return new LANGUAGE_CODES.en();
  }
}
