import { AbbreviationReplacer } from '../abbreviation_replacer';
import { BetweenPunctuation } from '../between_punctuation';
import { replacePunctuation } from '../punctuation_replacer';
import { LanguageBase } from './common/Base';

class ChineseAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

class ChineseBetweenPunctuation extends BetweenPunctuation {
  replace(): string {
    this.text = this.subPunctuationBetweenQuotesAndParens(this.text);
    return this.text;
  }

  subPunctuationBetweenDoubleAngledQuotationMarks(txt: string): string {
    const BETWEEN_DOUBLE_ANGLE_QUOTATION_MARK_REGEX = /《(?=(?<tmp>[^》\\]+|\\{2}|\\.)*)(?:\k<tmp>)》/gu;

    return txt.replace(BETWEEN_DOUBLE_ANGLE_QUOTATION_MARK_REGEX, (match) => replacePunctuation(match));
  }

  subPunctuationBetweenLBracket(txt: string): string {
    const BETWEEN_L_BRACKET_REGEX = /「(?=(?<tmp>[^」\\]+|\\{2}|\\.)*)(?:\k<tmp>)」/gu;

    return txt.replace(BETWEEN_L_BRACKET_REGEX, (match) => replacePunctuation(match));
  }

  subPunctuationBetweenQuotesAndParens(txt: string): string {
    txt = this.subPunctuationBetweenDoubleAngledQuotationMarks(txt);
    txt = this.subPunctuationBetweenLBracket(txt);
    return txt;
  }
}

export class Chinese extends LanguageBase {
  readonly isoCode = 'zh';

  readonly AbbreviationReplacer = ChineseAbbreviationReplacer;

  readonly BetweenPunctuation = ChineseBetweenPunctuation;
}
