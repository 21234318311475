import { AbbreviationReplacer as BaseAbbreviationReplacer } from '../abbreviation_replacer';
import { Rule } from '../utils';
import { LanguageBase } from './common/Base';
import { WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE } from './common/Standard';

class AbbreviationReplacer extends BaseAbbreviationReplacer {
  SENTENCE_STARTERS = [];

  scanForReplacements(txt: string, am: string, index: number, characterArray: string[]): string {
    return txt.replace(new RegExp(`(?<=${am})\\.`, 'gu'), '∯');
  }
}

export class Arabic extends LanguageBase {
  readonly isoCode = 'ar';

  readonly AbbreviationReplacer = AbbreviationReplacer;

  readonly Punctuations: string[] = ['?', '!', ':', '.', '؟', '،'];
  readonly SENTENCE_BOUNDARY_REGEX = '.*?[:.!\\?؟،]|.*?\\Z|.*?$';

  readonly ReplaceColonBetweenNumbersRule = new Rule(/(?<=\d):(?=\d)/u, '♭');
  readonly ReplaceNonSentenceBoundaryCommaRule = new Rule(/،(?=\s\S+،)/u, '♬');

  readonly Abbreviation = {
    ABBREVIATIONS: [
      'ا',
      'ا. د',
      'ا.د',
      'ا.ش.ا',
      'ا.ش.ا',
      'إلخ',
      'ت.ب',
      'ت.ب',
      'ج.ب',
      'جم',
      'ج.ب',
      'ج.م.ع',
      'ج.م.ع',
      'س.ت',
      'س.ت',
      'سم',
      'ص.ب.',
      'ص.ب',
      'كج.',
      'كلم.',
      'م',
      'م.ب',
      'م.ب',
      'ه',
    ],
    PREPOSITIVE_ABBREVIATIONS: [],
    NUMBER_ABBREVIATIONS: [],
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };
}
