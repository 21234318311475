import { LanguageBase } from './common/Base';
import { SINGLE_LETTER_ABBREVIATION_RULES } from './common/Common';

export class English extends LanguageBase {
  readonly isoCode = 'en';

  readonly SENTENCE_STARTERS = [
    'A',
    'Being',
    'Did',
    'For',
    'He',
    'How',
    'However',
    'I',
    'In',
    'It',
    'Millions',
    'More',
    'She',
    'That',
    'The',
    'There',
    'They',
    'We',
    'What',
    'When',
    'Where',
    'Who',
    'Why',
  ];
}
