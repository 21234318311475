import * as React from 'react';
import useSWR from 'swr';

import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '../../../utils/fetch.client';
import { ResponseType as CategoryResult } from '../endpoints/ListCategoriesEndpoint';
import { getTeamStates } from '@/app/team/context/TeamStates';

export type Category = CategoryResult['items'][0];

export interface ICategoryNode {
  id: string;
  name: string;
  description: string;
  parentCategoryId: string | null;
  children: ICategoryNode[];
}

interface ReturnValue {
  isLoading: boolean;
  template: {
    id: string;
    name: string;
    createdAt: string;
  } | null;
  categories: Category[];
  categoryMap: Map<string, Category>;
  categoryTree: ICategoryNode[];
  refetch: () => void;
}

export function useCategories(templateId: string): ReturnValue {
  const { team } = useTeam();
  const { data, isLoading, mutate } = useSWR<CategoryResult>(
    `/api/v1/category/list?teamId=${team.id}&templateId=${templateId}`,
    fetchEndpointData,
  );
  const teamState = getTeamStates().getState(team.id);

  const refetch = React.useCallback(() => {
    mutate();
  }, [mutate]);

  React.useEffect(() => {
    teamState.onCategoriesUpdate(() => {
      refetch();
    });
  }, [teamState, refetch]);

  const template = data?.template ?? null;
  const rawCategories = data?.items ?? [];
  const { categories, categoryMap, categoryTree } = React.useMemo(() => {
    const categories = [...rawCategories];
    const categoryMap = new Map(categories.map((v) => [v.id, v]));

    const nodeMap: Map<string, ICategoryNode> = new Map();
    const categoryTree = [] as ICategoryNode[];

    let pendingItems = [...categories];
    let iterCount = 0;
    while (pendingItems.length > 0 && iterCount < 5000) {
      iterCount++;

      for (const pendingItem of pendingItems) {
        if (!pendingItem.parentCategoryId) {
          const node: ICategoryNode = {
            id: pendingItem.id,
            name: pendingItem.name,
            description: pendingItem.description,
            parentCategoryId: null,
            children: [],
          };
          categoryTree.push(node);
          nodeMap.set(pendingItem.id, node);
          pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
        } else {
          const parentNode = nodeMap.get(pendingItem.parentCategoryId);
          if (parentNode) {
            const node: ICategoryNode = {
              id: pendingItem.id,
              name: pendingItem.name,
              description: pendingItem.description,
              parentCategoryId: pendingItem.parentCategoryId,
              children: [],
            };
            parentNode.children.push(node);
            nodeMap.set(pendingItem.id, node);
            pendingItems = pendingItems.filter((v) => v.id !== pendingItem.id);
          }
        }
      }
    }

    return {
      categories,
      categoryMap,
      categoryTree,
    };
  }, [data]);

  return { isLoading, template, categories, categoryMap, categoryTree, refetch };
}
