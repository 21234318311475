import { Rule, Text } from './utils';

export class EscapeRegexReservedCharacters {
  static readonly LeftParen = new Rule(/\(/, '\\(');
  static readonly RightParen = new Rule(/\)/, '\\)');
  static readonly LeftBracket = new Rule(/\[/, '\\[');
  static readonly RightBracket = new Rule(/\]/, '\\]');
  static readonly Dash = new Rule(/\-/, '\\-');

  static readonly All = [
    EscapeRegexReservedCharacters.LeftParen,
    EscapeRegexReservedCharacters.RightParen,
    EscapeRegexReservedCharacters.LeftBracket,
    EscapeRegexReservedCharacters.RightBracket,
    EscapeRegexReservedCharacters.Dash,
  ];
}

export class SubEscapedRegexReservedCharacters {
  static readonly SubLeftParen = new Rule(/\\\(/, '(');
  static readonly SubRightParen = new Rule(/\\\)/, ')');
  static readonly SubLeftBracket = new Rule(/\\\[/, '[');
  static readonly SubRightBracket = new Rule(/\\\]/, ']');
  static readonly SubDash = new Rule(/\\\-/, '-');

  static readonly All = [
    SubEscapedRegexReservedCharacters.SubLeftParen,
    SubEscapedRegexReservedCharacters.SubRightParen,
    SubEscapedRegexReservedCharacters.SubLeftBracket,
    SubEscapedRegexReservedCharacters.SubRightBracket,
    SubEscapedRegexReservedCharacters.SubDash,
  ];
}

interface RegExpMatchResult {
  group: () => string;
}

export function replacePunctuation(match: RegExpMatchResult | string, matchType?: string): string {
  const matchText = typeof match === 'string' ? match : match.group();
  let text = new Text(matchText).apply(...EscapeRegexReservedCharacters.All);

  text = text
    .replace(/\./g, '∯')
    .replace(/\。/g, '&ᓰ&')
    .replace(/\．/g, '&ᓱ&')
    .replace(/\！/g, '&ᓳ&')
    .replace(/\!/g, '&ᓴ&')
    .replace(/\?/g, '&ᓷ&')
    .replace(/\？/g, '&ᓸ&');

  if (matchType !== 'single') {
    text = text.replace(/'/g, '&⎋&');
  }

  return new Text(text).apply(...SubEscapedRegexReservedCharacters.All);
}
