import { CustomError } from '../app/error/CustomError';
import { ErrorCode } from '../app/error/ErrorCodes';

const prefix: string = 'Invariant failed';

// Throw an error if the condition fails
// Strip out error messages for production
// > Not providing an inline default argument for message as the result is smaller
export function invariant(
  condition: any,
  // Can provide a string, or a function that returns a string for cases where
  // the message takes a fair amount of effort to compute
  message?: string | (() => string),
): asserts condition {
  if (condition) {
    return;
  }

  const provided: string | undefined = typeof message === 'function' ? message() : message;

  // Options:
  // 1. message provided: `${prefix}: ${provided}`
  // 2. message not provided: prefix
  const value: string = provided ? `${prefix}: ${provided}` : prefix;
  throw new CustomError(value, ErrorCode.VALIDATION_ERROR);
}

export function nullthrows<T>(value: T | null | undefined, message: string): T {
  invariant(value != null, message);
  return value;
}

export function nullthrowsNotTest<T>(value: T | null | undefined, message: string): T {
  try {
    invariant(value != null, message);
    return value;
  } catch (err) {
    if (process.env.NODE_ENV === 'test') {
      console.warn(message);
      return value as T;
    }

    throw err;
  }
}
