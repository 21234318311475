import { Emitter } from '@/utils/emitter';
import {
  DocumentFailureReason,
  DocumentIndexingStatus,
  getDocumentFailureReason,
  getDocumentIndexingStatus,
  getJurisdiction,
  getLanguage,
  JurisdictionEnum,
  LanguageEnum,
} from '../enums';
import { Throttle } from '@/utils/Throttle';
import { fetchEndpointData } from '@/utils/fetch.client';
import type { ResponseType as SyncDocumentsResponseType } from '../endpoints/SyncWorkspaceDocumentsEndpoint';
import type { ResponseType as SyncKeyResponseType } from '../endpoints/WorkspaceDocumentTreeSyncKeyEndpoint';
import type { ResponseType as DeletedEntriesResponseType } from '../endpoints/DeletedWorkspaceDocumentsLogEndpoint';
import type { ResponseType as SyncFoldersResponseType } from '../../workspaceFolder/endpoints/SyncWorkspaceFoldersEndpoint';
import { nullthrows } from '@/utils/invariant';
import { getWorkspaceStates } from '@/app/workspace/context/WorkspaceStates';

export const TREE_ROOT_ID = 'ROOT';

const PAGE_SIZE = 500;

type TreeNodeType = 'document' | 'folder';

type RawDocument = SyncDocumentsResponseType['documents'][0];
type RawFolder = SyncFoldersResponseType['folders'][0];
type DeletedEntry = DeletedEntriesResponseType['entries'][0];

export interface ICategory {
  id: string;
  name: string;
  similarity?: number | null;
}

export interface IFolderData {
  id: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface IDocumentData {
  id: string;
  indexingStatus: DocumentIndexingStatus;
  failureReason: DocumentFailureReason | null;
  hasExtractedMetadata: boolean;
  hasExtractedParties: boolean;
  date?: Date | null;
  language?: LanguageEnum | null;
  jurisdiction?: JurisdictionEnum | null;
  ocrConfidence?: number | null;
  contractValue?: number | null;
  contractCurrency?: string | null;
  createdAt: Date;
  updatedAt: Date;
  categories: Array<ICategory>;
  parties: Array<{
    id: string;
    isPerson: boolean;
    name: string;
    companyName?: string | null;
    companyNumber?: string | null;
    roles: string[];
  }>;
  tableOfContents: string[];
  fileSize: number;
  pageCount: number;
}

export class TreeNode {
  id: string;
  folder?: IFolderData;
  document?: IDocumentData;
  // this is a folder id not an explorer id
  parentFolderId: string | null;
  name: string;
  type: TreeNodeType;
  private _children: TreeNode[] = [];
  private _childrenIds = new Set<string>();
  updatedAt: Date;

  constructor(id: string, parentFolderId: string | null, name: string, type: TreeNodeType, updatedAt: Date) {
    this.id = id;
    this.parentFolderId = parentFolderId;
    this.name = name;
    this.type = type;
    this.updatedAt = updatedAt;
  }

  addChild(child: TreeNode) {
    if (this._childrenIds.has(child.id)) {
      this.removeChild(child.id);
    }

    this._children.push(child);
    this._childrenIds.add(child.id);
  }

  removeChild(childId: string) {
    this._childrenIds.delete(childId);
    this._children = this._children.filter((child) => child.id !== childId);
  }

  getChildren() {
    return [...this._children];
  }

  childCount() {
    return this._children.length;
  }
}

function processRawDocument(rawDocument: RawDocument): IDocumentData {
  return {
    ...rawDocument,
    failureReason: rawDocument.failureReason ? getDocumentFailureReason(rawDocument.failureReason) : null,
    indexingStatus: getDocumentIndexingStatus(rawDocument.indexingStatus),
    jurisdiction: rawDocument.jurisdiction ? getJurisdiction(rawDocument.jurisdiction) : null,
    language: rawDocument.language ? getLanguage(rawDocument.language) : null,
    date: rawDocument.date ? new Date(rawDocument.date) : null,
    createdAt: new Date(rawDocument.createdAt),
    updatedAt: new Date(rawDocument.updatedAt),
  };
}

function processRawFolder(rawFolder: RawFolder): IFolderData {
  return {
    ...rawFolder,
    createdAt: new Date(rawFolder.createdAt),
    updatedAt: new Date(rawFolder.updatedAt),
  };
}

export class WorkspaceDocumentTree {
  isSyncing = false;
  lastDocumentUpdateAt = 0;
  lastFolderUpdateAt = 0;
  lastDeleteAt = 0;
  lastSyncKey = '';
  root = new TreeNode(TREE_ROOT_ID, null, 'home', 'folder', new Date());
  entries = new Map<string, TreeNode>();
  hasPendingChanges = false;
  hasPendingSync = false;

  pendingConnections = new Map<string, TreeNode[]>();

  public processingDocumentIds = new Set<string>();
  public invalidDocumentIds = new Set<string>();

  private isFirstSync = true;
  private treeChangeEmitter = new Emitter<void>();
  onTreeChange = this.treeChangeEmitter.event;
  private syncStateChangeEmitter = new Emitter<boolean>();
  onSyncStateChange = this.syncStateChangeEmitter.event;

  danglingNodes = new Map<string, TreeNode>();

  private syncThrottle = new Throttle(() => {
    this.runSync();
  }, 100);

  constructor(
    private teamId: string,
    private workspaceId: string,
  ) {
    const syncState = getWorkspaceStates().getState(workspaceId, teamId);
    syncState.onDocumentsUpdate(() => {
      this.syncThrottle.execute();
    });
    syncState.onFoldersUpdate(() => {
      this.syncThrottle.execute();
    });
    this.syncThrottle.execute();

    this.entries.set(TREE_ROOT_ID, this.root);
  }

  updateProcessingDocumentsCount() {
    this.processingDocumentIds = new Set<string>();
    for (const entry of this.entries.values()) {
      if (
        entry.document &&
        entry.document.indexingStatus !== DocumentIndexingStatus.Indexed &&
        entry.document.indexingStatus !== DocumentIndexingStatus.Invalid
      ) {
        this.processingDocumentIds.add(entry.id);
      }
    }
  }

  updateInvalidDocumentsCount() {
    this.invalidDocumentIds = new Set<string>();
    for (const entry of this.entries.values()) {
      if (entry.document && entry.document.indexingStatus === DocumentIndexingStatus.Invalid) {
        this.invalidDocumentIds.add(entry.id);
      }
    }
  }

  emitChanges() {
    if (this.hasPendingChanges) {
      this.updateProcessingDocumentsCount();
      this.updateInvalidDocumentsCount();

      this.treeChangeEmitter.fire();
      this.hasPendingChanges = false;
    }
  }

  updateSyncState(newState: boolean) {
    this.isSyncing = newState;
    this.syncStateChangeEmitter.fire(newState);
  }

  private async runSync(): Promise<void> {
    if (this.isSyncing) {
      this.hasPendingSync = true;
      return;
    }

    this.updateSyncState(true);
    try {
      const syncKey = await this.fetchSyncKey();
      if (syncKey !== this.lastSyncKey) {
        console.log('Starting document tree sync...');
        let startTimeMs = Date.now();
        await this.fetchAllFolderUpdates();
        await this.fetchAllDocumentUpdates();
        const duration = Date.now() - startTimeMs;

        // On the first sync we skip all deletes, document and folder updates that happened after the initial sync
        // We might need to keep in mind the amount of time it took to perform the initial sync?
        if (this.isFirstSync) {
          const otherUpdatedAtDate = Math.max(this.lastDocumentUpdateAt, this.lastFolderUpdateAt) - duration;

          this.lastFolderUpdateAt = otherUpdatedAtDate;
          this.lastDocumentUpdateAt = otherUpdatedAtDate;
          this.lastDeleteAt = otherUpdatedAtDate;
        }

        await this.fetchAllDeletedExplorerEntries();

        this.handleDanglingNodes();

        console.log('Document tree sync finished');

        this.isFirstSync = false;
        this.lastSyncKey = syncKey;

        this.emitChanges();
      } else {
        console.log('Skipping sync, sync key is the same');
      }
    } catch (err) {
      console.error(err);
    }
    this.updateSyncState(false);

    if (this.hasPendingSync) {
      this.hasPendingSync = false;
      this.syncThrottle.execute();
    }
  }

  handleDanglingNodes() {
    let hasUpdated = false;

    const initialDanglingNodes = this.danglingNodes.size;
    if (initialDanglingNodes > 0) {
      console.log('Initial dangling nodes:', initialDanglingNodes);
    }

    while (true) {
      hasUpdated = false;
      for (const node of this.danglingNodes.values()) {
        if (this.entries.has(node.id)) {
          this.danglingNodes.delete(node.id);
          continue;
        }

        const parentFolderNode = this.getFolderNode(node.parentFolderId ?? TREE_ROOT_ID);
        if (parentFolderNode) {
          this.entries.set(node.id, node);
          parentFolderNode.addChild(node);
          this.danglingNodes.delete(node.id);
          hasUpdated = true;
          this.hasPendingChanges = true;
        }
      }

      if (!hasUpdated) {
        break;
      }
    }

    if (this.danglingNodes.size > 0) {
      console.log('Remaining dangling nodes:', this.danglingNodes.size);
    } else if (initialDanglingNodes > 0) {
      console.log('All dangling nodes have been resolved');
    }
  }

  updateNodeParent(nodeId: string, newParentId: string) {
    // Cannot move the root node
    if (nodeId === TREE_ROOT_ID) {
      return;
    }

    const node = this.entries.get(nodeId);
    if (!node) {
      throw new Error('Node not found trying to update parent');
    }

    const oldParentId = node.parentFolderId ?? TREE_ROOT_ID;
    const newParentNode = nullthrows(this.entries.get(newParentId), 'Parent node not found when updating parent');
    if (node.parentFolderId !== newParentId) {
      const oldParentNode = this.entries.get(oldParentId);
      if (oldParentNode) {
        oldParentNode.removeChild(node.id);
      }
    }

    node.parentFolderId = newParentId;

    newParentNode.addChild(node);
  }

  updateDocument(rawDocument: RawDocument) {
    this.lastDocumentUpdateAt = Math.max(this.lastDocumentUpdateAt, new Date(rawDocument.updatedAt).getTime());

    const node = this.entries.get(rawDocument.id);
    if (node) {
      const processedDoc = processRawDocument(rawDocument);
      if (node.document && node.updatedAt.toISOString() === processedDoc.updatedAt.toISOString()) {
        return;
      }

      node.document = processedDoc;
      if (node.name !== rawDocument.name) {
        node.name = rawDocument.name;
      }
      node.updatedAt = processedDoc.updatedAt;
      this.updateNodeParent(rawDocument.id, rawDocument.workspaceFolderId ?? TREE_ROOT_ID);
    } else {
      const parentFolderId = rawDocument.workspaceFolderId ?? TREE_ROOT_ID;
      const parentFolderNode = this.getFolderNode(parentFolderId);

      const processedDoc = processRawDocument(rawDocument);
      const newNode = new TreeNode(
        rawDocument.id,
        parentFolderId,
        rawDocument.name,
        'document',
        processedDoc.updatedAt,
      );
      newNode.document = processedDoc;

      if (!parentFolderNode) {
        this.danglingNodes.set(newNode.id, newNode);
      } else {
        this.entries.set(rawDocument.id, newNode);
        parentFolderNode.addChild(newNode);
      }
    }
    this.hasPendingChanges = true;
  }

  updateFolder(rawFolder: RawFolder) {
    this.lastFolderUpdateAt = Math.max(this.lastFolderUpdateAt, new Date(rawFolder.updatedAt).getTime());

    const node = this.getFolderNode(rawFolder.id);
    if (node) {
      const processedFolder = processRawFolder(rawFolder);
      if (node.folder && node.updatedAt.toISOString() === processedFolder.updatedAt.toISOString()) {
        return;
      }

      node.folder = processedFolder;
      if (node.name !== rawFolder.name) {
        node.name = rawFolder.name;
      }
      node.updatedAt = processedFolder.updatedAt;
      this.updateNodeParent(rawFolder.id, rawFolder.parentFolderId ?? TREE_ROOT_ID);
    } else {
      const parentFolderId = rawFolder.parentFolderId ?? TREE_ROOT_ID;
      const parentFolderNode = this.getFolderNode(parentFolderId);

      const processedFolder = processRawFolder(rawFolder);
      const newNode = new TreeNode(rawFolder.id, parentFolderId, rawFolder.name, 'folder', processedFolder.updatedAt);
      newNode.folder = processedFolder;

      if (!parentFolderNode) {
        this.danglingNodes.set(rawFolder.id, newNode);
      } else {
        this.entries.set(rawFolder.id, newNode);
        parentFolderNode.addChild(newNode);
      }
    }
    this.hasPendingChanges = true;
  }

  deleteEntry(id: string, deletedAt: string) {
    this.lastDeleteAt = Math.max(this.lastDeleteAt, new Date(deletedAt).getTime());

    const foundEntry = this.entries.get(id);
    if (!foundEntry) {
      // console.warn('Trying to delete entry that does not exist', id);
      return;
    }

    if (this.danglingNodes.has(id)) {
      this.danglingNodes.delete(id);
    }

    if (foundEntry.parentFolderId) {
      const parentEntry = this.getFolderNode(foundEntry.parentFolderId);
      if (parentEntry) {
        parentEntry.removeChild(foundEntry.id);
      } else {
        console.warn('Parent node not found when deleting entry', foundEntry.parentFolderId);
      }
    } else {
      this.root.removeChild(foundEntry.id);
    }
    this.entries.delete(id);

    this.hasPendingChanges = true;
  }

  async fetchAllDocumentUpdates(): Promise<void> {
    const since = new Date(this.lastDocumentUpdateAt).toISOString();

    let cursor: string | undefined = undefined;
    while (true) {
      const updates = await this.fetchDocumentUpdates(since, cursor);

      if (!updates.length || updates[updates.length - 1]?.id === cursor) {
        break;
      }

      for (const update of updates) {
        this.updateDocument(update);
        cursor = update.id;
      }

      if (updates.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchDocumentUpdates(since: string, cursor: string | undefined): Promise<RawDocument[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<SyncDocumentsResponseType>(
      `/api/v1/workspace/document-tree/sync-documents/${this.workspaceId}?${searchQuery.toString()}`,
    );
    return results.documents;
  }

  async fetchAllFolderUpdates(): Promise<void> {
    const since = new Date(this.lastFolderUpdateAt).toISOString();

    let cursor: string | undefined = undefined;
    while (true) {
      const updates = await this.fetchFolderUpdates(since, cursor);
      if (!updates.length || updates[updates.length - 1]?.id === cursor) {
        break;
      }

      for (const update of updates) {
        this.updateFolder(update);
        cursor = update.id;
      }

      if (updates.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchFolderUpdates(since: string, cursor: string | undefined): Promise<RawFolder[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<SyncFoldersResponseType>(
      `/api/v1/workspace/document-tree/sync-folders/${this.workspaceId}?${searchQuery.toString()}`,
    );
    return results.folders;
  }

  private async fetchAllDeletedExplorerEntries(): Promise<void> {
    const since = new Date(this.lastDeleteAt).toISOString();
    let cursor: string | undefined = undefined;
    while (true) {
      const entries = await this.fetchDeletedExplorerEntries(since, cursor);
      if (!entries.length || entries[entries.length - 1]?.id === cursor) {
        break;
      }

      for (const entry of entries) {
        this.deleteEntry(entry.id, entry.deletedAt);
        cursor = entry.id;
      }

      if (entries.length < PAGE_SIZE) {
        break;
      }
    }
  }

  async fetchDeletedExplorerEntries(since: string, cursor: string | undefined): Promise<DeletedEntry[]> {
    const searchQuery = new URLSearchParams();
    if (cursor) {
      searchQuery.set('id', cursor);
    }
    searchQuery.set('take', PAGE_SIZE.toString());
    searchQuery.set('since', since);
    const results = await fetchEndpointData<DeletedEntriesResponseType>(
      `/api/v1/workspace/document-tree/deleted-documents/${this.workspaceId}?${searchQuery.toString()}`,
    );
    return results.entries;
  }

  async fetchSyncKey() {
    const results = await fetchEndpointData<SyncKeyResponseType>(
      `/api/v1/workspace/document-tree/sync-key/${this.workspaceId}`,
    );
    return results.syncKey ?? '';
  }

  getDocumentNode(documentId: string): TreeNode | undefined {
    return this.entries.get(documentId);
  }

  getFolderNode(folderId: string): TreeNode | undefined {
    return this.entries.get(folderId);
  }

  getNode(folderId: string): TreeNode | undefined {
    return this.entries.get(folderId);
  }

  getChildFolders(folderId: string): string[] {
    const explorerEntry = this.entries.get(folderId);
    if (!explorerEntry) {
      return [folderId];
    }

    const folderIds = new Set<string>([folderId]);
    for (const child of explorerEntry.getChildren()) {
      if (child.type === 'folder') {
        if (!child.folder?.id || folderIds.has(child.folder.id)) {
          continue;
        }

        folderIds.add(child.folder.id);
        const childFolderIds = this.getChildFolders(child.folder.id);
        for (const childFolderId of childFolderIds) {
          folderIds.add(childFolderId);
        }
      }
    }
    return [...folderIds];
  }

  getChildDocuments(folderId: string, includeNested: boolean = false): string[] {
    const explorerEntry = this.entries.get(folderId);
    if (!explorerEntry) {
      return [];
    }

    const documentIds = new Set<string>();
    for (const child of explorerEntry.getChildren()) {
      if (child.type === 'document') {
        if (!child.document?.id) {
          continue;
        }

        documentIds.add(child.document.id);
      } else if (includeNested && child.type === 'folder') {
        if (!child.folder?.id) {
          continue;
        }

        const childDocumentIds = this.getChildDocuments(child.folder.id, true);
        for (const childDocumentId of childDocumentIds) {
          documentIds.add(childDocumentId);
        }
      }
    }
    return [...documentIds];
  }

  getPendingDocuments() {
    const pendingDocuments: TreeNode[] = [];
    for (const entry of this.entries) {
      if (entry[1].document && entry[1].document.indexingStatus !== DocumentIndexingStatus.Indexed) {
        pendingDocuments.push(entry[1]);
      }
    }
    return pendingDocuments;
  }

  getDocumentIdsFromFoldersAndDocuments(
    folderIds: (string | null)[],
    documentIds: string[],
    includeSubFolders: boolean,
  ): Set<string> {
    let docIds = new Set<string>();
    for (const folderId of folderIds) {
      docIds = this._getDocumentIdsForFolderId(folderId ?? TREE_ROOT_ID, includeSubFolders, docIds);
    }
    for (const documentId of documentIds) {
      if (this.entries.get(documentId)) {
        docIds.add(documentId);
      }
    }
    return docIds;
  }

  getFullpath(nodeId: string): string {
    if (nodeId === TREE_ROOT_ID) {
      return '/';
    }

    const node = this.entries.get(nodeId);
    if (!node) {
      throw new Error('Node not found');
    }

    const parentFullpath = this.getFullpath(node.parentFolderId ?? TREE_ROOT_ID);
    if (node.type === 'document') {
      return `${parentFullpath}${node.name}`;
    } else {
      return `${parentFullpath}${node.name}/`;
    }
  }

  private _getDocumentIdsForFolderId(
    folderId: string,
    includeSubFolders: boolean,
    documentIds: Set<string>,
  ): Set<string> {
    const entry = this.entries.get(folderId);
    if (!entry) {
      return documentIds;
    }

    for (const child of entry.getChildren()) {
      if (child.type === 'document') {
        documentIds.add(child.id);
      }

      if (includeSubFolders && child.type === 'folder') {
        documentIds = this._getDocumentIdsForFolderId(child.id, true, documentIds);
      }
    }

    return documentIds;
  }

  getDocumentsInCategory(categoryId: string): string[] {
    const documentIds = new Set<string>();
    this.entries.forEach((value) => {
      if (value.document && value.document.categories.some((v) => v.id === categoryId)) {
        documentIds.add(value.id);
      }
    });
    return [...documentIds];
  }
}

const existingDocumentTree = new Map<string, WorkspaceDocumentTree>();
export function getWorkspaceDocumentTreeState(teamId: string, workspaceId: string) {
  const tree = existingDocumentTree.get(workspaceId) ?? new WorkspaceDocumentTree(teamId, workspaceId);
  existingDocumentTree.set(workspaceId, tree);
  return tree;
}
