import { AbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';

class HindiAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

export class Hindi extends LanguageBase {
  readonly isoCode = 'hi';

  readonly Punctuations = ['।', '|', '.', '!', '?'];

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[।|!?]|.*?$';

  readonly AbbreviationReplacer = HindiAbbreviationReplacer;
}
