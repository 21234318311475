import { AbbreviationReplacer as BaseAbbreviationReplacer } from '../../abbreviation_replacer';
import { BetweenPunctuation } from '../../between_punctuation';
import { Cleaner } from '../../Cleaner';
import { ListItemReplacer } from '../../list_item_replacer';
import { Processor } from '../../Processor';
import { Rule } from '../../utils';

import {
  AM_PM_RULES,
  KOMMANDITGESELLSCHAFT_RULE,
  NUMBER_RULES,
  POSSESSIVE_ABBREVIATION_RULE,
  SINGLE_LETTER_ABBREVIATION_RULES,
} from './Common';
import {
  ABBREVIATIONS,
  DOUBLE_PUNCTUATION_RULES,
  ELLIPSIS_RULES,
  EXCLAMATION_POINT_RULES,
  EXTRA_WHITE_SPACE_RULE,
  FILE_FORMAT_RULE,
  GEO_LOCATION_RULE,
  NUMBER_ABBREVIATIONS,
  PREPOSITIVE_ABBREVIATIONS,
  PUNCTUATIONS,
  QUESTION_MARK_IN_QUOTATION_RULE,
  REINSERT_ELLIPSIS_RULES,
  SENTENCE_STARTERS,
  SINGLE_NEW_LINE_RULE,
  SUB_SINGLE_QUOTE_RULE,
  SUB_SYMBOLS_RULES,
  WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
} from './Standard';

export class LanguageBase {
  readonly AbbreviationReplacer?: typeof BaseAbbreviationReplacer;
  readonly BetweenPunctuation?: typeof BetweenPunctuation;
  readonly Cleaner?: typeof Cleaner;
  readonly Processor?: typeof Processor;
  readonly ListItemReplacer?: typeof ListItemReplacer;
  readonly ReplaceColonBetweenNumbersRule?: Rule;
  readonly ReplaceNonSentenceBoundaryCommaRule?: Rule;

  // Common
  readonly SENTENCE_BOUNDARY_REGEX: string =
    '（(?:[^）])*）(?=\\s?[A-Z])|「(?:[^」])*」(?=\\s[A-Z])|\\((?:[^\\)]){2,}\\)(?=\\s[A-Z])|\'(?:[^\'])*[^,]\'(?=\\s[A-Z])|\\"(?:[^\\"])*[^,]\\"(?=\\s[A-Z])|\\"(?:[^\\"])*[^,]\\"(?=\\s[A-Z])|[。．.！!?？ ]{2,}|\\S.*?[。．.！!?？ȸȹ☉☈☇☄]|[。．.！!?？]';

  readonly QUOTATION_AT_END_OF_SENTENCE_REGEX = /[!?\.-][\"\'""]\s{1}[A-Z]/g;
  readonly PARENS_BETWEEN_DOUBLE_QUOTES_REGEX = /["\"]\s\(.*\)\s["\""]/g;
  readonly SPLIT_SPACE_QUOTATION_AT_END_OF_SENTENCE_REGEX = /(?<=[!?\.-][\"\'""])\s{1}(?=[A-Z])/g;
  readonly CONTINUOUS_PUNCTUATION_REGEX = /(?<=\S)(!|\?){3,}(?=(\s|\Z|$))/g;
  readonly NUMBERED_REFERENCE_REGEX =
    /(?<=[^\d\s])(?:\.|∯)(?:\[\d{1,3}(?:,?\s?-?\s?\d{1,3})*\]|\d{1,3}(?:\s?\d{1,3})?)\s(?=[A-Z])/g;
  readonly MULTI_PERIOD_ABBREVIATION_REGEX = /\b[a-z](?:\.[a-z])+[.]/g;

  readonly PossessiveAbbreviationRule = POSSESSIVE_ABBREVIATION_RULE;
  readonly KommanditgesellschaftRule = KOMMANDITGESELLSCHAFT_RULE;
  readonly SingleLetterAbbreviationRules = SINGLE_LETTER_ABBREVIATION_RULES;
  readonly AmPmRules = AM_PM_RULES;
  readonly Numbers = NUMBER_RULES;
  readonly MONTHS: string[] = [];

  // Standard
  readonly Punctuations = PUNCTUATIONS;
  readonly GeoLocationRule = GEO_LOCATION_RULE;
  readonly FileFormatRule = FILE_FORMAT_RULE;
  readonly SingleNewLineRule = SINGLE_NEW_LINE_RULE;
  readonly QuestionMarkInQuotationRule = QUESTION_MARK_IN_QUOTATION_RULE;
  readonly ExtraWhiteSpaceRule = EXTRA_WHITE_SPACE_RULE;
  readonly SubSingleQuoteRule = SUB_SINGLE_QUOTE_RULE;

  readonly Abbreviation = {
    ABBREVIATIONS,
    PREPOSITIVE_ABBREVIATIONS,
    NUMBER_ABBREVIATIONS,
    WithMultiplePeriodsAndEmailRule: WITH_MULTIPLE_PERIODS_AND_EMAIL_RULE,
  };

  readonly DoublePunctuationRules = DOUBLE_PUNCTUATION_RULES;
  readonly ExclamationPointRules = EXCLAMATION_POINT_RULES;
  readonly SubSymbolsRules = SUB_SYMBOLS_RULES;
  readonly EllipsisRules = ELLIPSIS_RULES;
  readonly ReinsertEllipsisRules = REINSERT_ELLIPSIS_RULES;

  readonly SENTENCE_STARTERS = SENTENCE_STARTERS;
}
