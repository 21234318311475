export enum TeamPermissionEnum {
  CreateWorkspaces = 'create-workspaces',
  ManagePresets = 'manage-presets',
  ManageCategories = 'manage-categories',
}

export const TEAM_PERMISSION_SET = new Set<string>([...Object.values(TeamPermissionEnum)]);

export enum WorkspacePermissionEnum {
  WriteDocuments = 'write-documents',
  DeleteDocuments = 'delete-documents',
  ManageMembers = 'manage-members',
  RunPresets = 'run-presets', // Implies read-documents
  DeletePresetResults = 'delete-preset-results',
  Chat = 'chat', // Implies read-documents
  Summarize = 'summarize', // Implies read-documents
  ReadAuditLogs = 'read-audit-logs',
  ManageCategories = 'manage-categories',
}

export const WORKSPACE_PERMISSION_SET = new Set<string>([...Object.values(WorkspacePermissionEnum)]);
