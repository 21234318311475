import { AbbreviationReplacer } from '../abbreviation_replacer';
import { LanguageBase } from './common/Base';

class MaratiAbbreviationReplacer extends AbbreviationReplacer {
  SENTENCE_STARTERS = [];
}

export class Marathi extends LanguageBase {
  readonly isoCode = 'mr';

  readonly AbbreviationReplacer = MaratiAbbreviationReplacer;

  readonly SENTENCE_BOUNDARY_REGEX = '.*?[.!?]|.*?$';

  readonly Punctuations = ['.', '!', '?'];
}
