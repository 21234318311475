import useSWR from 'swr';

import { ResponseType as CategoryTemplatesResponse } from '../endpoints/ListCategoryTemplatesEndpoint';
import { useTeam } from '@/app/team/context/TeamContext';
import { fetchEndpointData } from '@/utils/fetch.client';

export function useCategoryTemplates() {
  const { team } = useTeam();
  const { data, isLoading, mutate } = useSWR<CategoryTemplatesResponse>(
    `/api/v1/category-template/list?teamId=${team.id}`,
    fetchEndpointData,
  );

  return { data, isLoading, mutate };
}
