import { Outlet } from 'react-router-dom';

import { TeamProvider } from '../context/TeamContext';

export const TeamWrapper = () => {
  return (
    <TeamProvider>
      <Outlet />
    </TeamProvider>
  );
};
