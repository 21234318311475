import { Outlet } from 'react-router-dom';

import { WorkspaceProvider } from '@/app/workspace/context/WorkspaceContext';
import { WorkspaceDashboard } from '@/components/page-templates/WorkspaceDashboard';
import { WorkspaceCategoriesProvider } from '@/app/workspaceCategory/contexts/WorkspaceCategoriesContext';

export const WorkspacePage = () => {
  return (
    <WorkspaceProvider>
      <WorkspaceCategoriesProvider>
        <WorkspaceDashboard>
          <Outlet />
        </WorkspaceDashboard>
      </WorkspaceCategoriesProvider>
    </WorkspaceProvider>
  );
};
