export class Rule {
  pattern: RegExp | string;
  replacement: string;

  constructor(pattern: RegExp | string, replacement: string) {
    this.pattern = pattern;
    this.replacement = replacement;
  }

  toString(): string {
    return `<${this.constructor.name} pattern="${this.pattern}" and replacement="${this.replacement}">`;
  }
}

export class Text {
  private content: string;

  constructor(text: string) {
    this.content = text;
  }

  apply(...rules: Rule[]): string {
    let result = this.content;
    for (const rule of rules) {
      result = result.replace(new RegExp(rule.pattern, 'gi'), rule.replacement);
    }
    return result;
  }

  toString(): string {
    return this.content;
  }
}

export class TextSpan {
  sent: string;
  start: number;
  end: number;

  constructor(sent: string, start: number, end: number) {
    this.sent = sent;
    this.start = start;
    this.end = end;
  }

  toString(): string {
    return `${this.constructor.name}(sent=${JSON.stringify(this.sent)}, start=${this.start}, end=${this.end})`;
  }

  equals(other: TextSpan): boolean {
    return this.sent === other.sent && this.start === other.start && this.end === other.end;
  }
}
