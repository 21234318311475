import { Segmenter } from './Segmenter';

export function getSentences(
  text: string,
  opts: {
    language?: string;
    clean?: boolean;
  } = {},
): string[] {
  const segmenter = new Segmenter({
    language: opts.language,
    clean: opts.clean ?? true,
  });
  const results = segmenter.segment(text) as string[];
  return results;
}
