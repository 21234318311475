import { LanguageBase } from './lang/common/Base';
import { Text } from './utils';

function replacePreNumberAbbr(txt: string, abbr: string): string {
  txt = ' ' + txt;
  txt = txt.replace(new RegExp(`(?<=\\s${abbr.trim()})\\.(?=(\\s\\d|\\s+\\())`, 'g'), '∯');
  return txt.substring(1);
}

function replacePositiveAbbr(txt: string, abbr: string): string {
  txt = ' ' + txt;
  txt = txt.replace(new RegExp(`(?<=\\s${abbr.trim()})\\.(?=(\\s|:\\d+))`, 'g'), '∯');
  return txt.substring(1);
}

export class AbbreviationReplacer {
  text: string;
  lang: LanguageBase;

  constructor(text: string, lang: LanguageBase) {
    this.text = text;
    this.lang = lang;
  }

  replace(): string {
    this.text = new Text(this.text).apply(
      this.lang.PossessiveAbbreviationRule,
      this.lang.KommanditgesellschaftRule,
      ...this.lang.SingleLetterAbbreviationRules.All,
    );

    let abbrHandledText = '';
    const lines = this.text.split(/\r?\n/);
    for (const line of lines) {
      abbrHandledText += this.searchForAbbreviationsInString(line);
    }

    this.text = abbrHandledText;
    this.replaceMultiPeriodAbbreviations();
    this.text = new Text(this.text).apply(...this.lang.AmPmRules.All);
    this.text = this.replaceAbbreviationAsSentenceBoundary();
    return this.text;
  }

  replaceAbbreviationAsSentenceBoundary() {
    const sentStarters = this.lang.SENTENCE_STARTERS.map((word: string) => `(?=\\s${word}\\s)`).join('|');
    const regex = new RegExp(`(U∯S|U\\.S|U∯K|E∯U|E\\.U|U∯S∯A|U\\.S\\.A|I|i\\.v|I\\.V)∯(${sentStarters})`, 'g');
    this.text = this.text.replace(regex, '$1.');
    return this.text;
  }

  replaceMultiPeriodAbbreviations(): void {
    this.text = this.text.replace(new RegExp(this.lang.MULTI_PERIOD_ABBREVIATION_REGEX, 'gi'), (match) =>
      match.replace(/\./g, '∯'),
    );
  }

  replacePeriodOfAbbr(txt: string, abbr: string): string {
    txt = ' ' + txt;
    const escapedAbbr = abbr.trim().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    txt = txt.replace(
      new RegExp(`(?<=\\s${escapedAbbr})\\.(?=((\\.|\\:|-|\\?|,)|(\\s([a-z]|I\\s|I'm|I'll|\\d|\\())))`, 'g'),
      '∯',
    );

    return txt.substring(1);
  }

  searchForAbbreviationsInString(text: string): string {
    const lowered = text.toLowerCase();

    for (const abbr of this.lang.Abbreviation.ABBREVIATIONS) {
      const stripped = abbr.trim();
      if (!lowered.includes(stripped)) {
        continue;
      }

      const abbrRegex = new RegExp(`(?:^|\\s|\\r|\\n)${stripped}`, 'gi');
      const abbrevMatch = text.match(abbrRegex);
      if (!abbrevMatch) {
        continue;
      }

      const nextWordStartRegex = new RegExp(`(?<=${stripped.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')} ).{1}`, 'g');
      const charArray = text.match(nextWordStartRegex) || [];

      for (let ind = 0; ind < abbrevMatch.length; ind++) {
        text = this.scanForReplacements(text, abbrevMatch[ind]!, ind, charArray);
      }
    }

    return text;
  }

  scanForReplacements(txt: string, am: string, ind: number, charArray: string[]): string {
    const char = charArray[ind] || '';
    const prepositive = this.lang.Abbreviation.PREPOSITIVE_ABBREVIATIONS;
    const numberAbbr = this.lang.Abbreviation.NUMBER_ABBREVIATIONS;
    const upper = char.toUpperCase() === char;
    const amLower = am.trim().toLowerCase();

    if (!upper || prepositive.includes(amLower)) {
      if (prepositive.includes(amLower)) {
        txt = replacePositiveAbbr(txt, am);
      } else if (numberAbbr.includes(amLower)) {
        txt = replacePreNumberAbbr(txt, am);
      } else {
        txt = this.replacePeriodOfAbbr(txt, am);
      }
    }

    return txt;
  }
}
